import React, {useState} from "react"
import Button from '@mui/material/Button';
import Image1 from '../../../assets/Uploads/img_1.jpg'
import HeaderLogo from '../../../assets/logo.png'
import Image2 from '../../../assets/Uploads/img_2.jpg'
import Image3 from '../../../assets/Uploads/home/shutterstock_2000268743.webp'
import AppertflowIsotipo from '../../../assets/Uploads/appertflow-isotipo.png'
import LabnotesIsotipo from '../../../assets/Uploads/labnotes-isotipo.png'
import Link from '@mui/material/Link';
import {RouteUser} from '../../../routes/RouteUser'
import SciFoodStudioExe from "../../../assets/Files/SciFood_Studio_2.exe";
import DownloadModal from "../../../components/Layouts/User/DownloadModal";

export default function Block1() {

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleClick = () => {
        setModalIsOpen(true)
    };

    return (
        <div className="block1">
            <DownloadModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
            <div className="image1-container">
                <img src={Image1} className="image1" />
            </div>
            <div className="absolute-container">
                <div className="header-text">
                    <img src={HeaderLogo} className="header-logo" />
                    <h1>SciFood Studio</h1>
                    <h3>Liderando el camino en seguridad alimentaria</h3>
                    <Link href={SciFoodStudioExe} download="SciFoodStudio.exe" onClick={handleClick} underline="none" color="inherit"><Button className="header-button">Descargar SciFood Studio</Button></Link>
                </div>
                <div className="absolute-blocks">
                    <div className="appert-flow-container">
                        <div className="Image4-container">
                            <img src={Image2} className="Image4" />
                        </div>
                        <div className="appert-flow-text-container-mobile">
                            <p>Supera los requisitos de calidad y seguridad más exigentes con la ayuda de AppertFlow, un software para validar, vigilar, verificar y optimizar los lotes de alimentos líquidos esterilizados térmicamente.</p>
                        </div>
                        <div className="appert-flow-content">
                            <div className="appert-flow-isotipo-container">
                                <img src={AppertflowIsotipo} className="appert-flow-isotipo" />
                            </div>
                            <div className="appert-flow-text">
                                <h2>AppertFlow</h2>
                                <div className="appert-flow-text-container">
                                    <p>Supera los requisitos de calidad y seguridad más exigentes con la ayuda de AppertFlow, un software para validar, vigilar, verificar y optimizar los lotes de alimentos líquidos esterilizados térmicamente.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="labnotes-isotipo-container">
                        <div className="Image5-container">
                            <img src={Image3} className="Image5" />
                        </div>
                        <div className="labnotes-isotipo-text-container-mobile">
                            <p>Evita la dispersión y deslocalización de datos, garantizando la trazabilidad mediante LabNotes, una herramienta para anotar y registrar toda la información analítica de los alimentos.</p>
                        </div>
                        <div className="labnotes-isotipo-content">
                            <div className="labnotes-isotipo-img-container">
                                <img src={LabnotesIsotipo} className="labnotes-isotipo" />
                            </div>
                            <div className="labnotes-isotipo-text">
                                <h2>LabNotes</h2>
                                <div className="labnotes-isotipo-text-container">
                                    <p>Evita la dispersión y deslocalización de datos, garantizando la trazabilidad mediante LabNotes, una herramienta para anotar y registrar toda la información analítica de los alimentos.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
