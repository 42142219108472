import Homepage from "../pages/User/Homepage"
import CookiesPolicy from "../pages/User/CookiesPolicy"
import DemoRequest from "../pages/User/DemoRequest"
import LegalConditions from "../pages/User/LegalConditions"
import PrivacyPolicy from "../pages/User/PrivacyPolicy"
import Training from "../pages/User/Training"
import Distributor from "../pages/User/Distributor"
import Student from "../pages/User/Student"
import AboutUs from "../pages/User/AboutUs"
import LabNotes from "../pages/User/LabNotes"
import AppertFlow from "../pages/User/AppertFlow"
import Support from "../pages/User/Support"
import SupportContent from "../pages/User/SupportContent"


const baseTitle = "SciFood - "

export const RouteUser = {
    ROOT: {
        path: "/",
        page: Homepage,
        title: `${baseTitle}Homepage`,
        roles: ["ROLE_PUBLIC"]
    },
    DEMO_REQUEST: {
        path: "/demo-request",
        page: DemoRequest,
        title: `${baseTitle}Homepage`,
        roles: ["ROLE_PUBLIC"]
    },
    COOKIES_POLICY: {
        path: "/cookies-policy",
        page: CookiesPolicy,
        title: `${baseTitle}cookies policy`,
        roles: ["ROLE_PUBLIC"]
    },
    LEGAL_CONDITIONS: {
        path: "/legal-conditions",
        page: LegalConditions,
        title: `${baseTitle}legal conditions`,
        roles: ["ROLE_PUBLIC"]
    },
    PRIVACY_POLICY: {
        path: "/privacy-policy",
        page: PrivacyPolicy,
        title: `${baseTitle}privacy policy`,
        roles: ["ROLE_PUBLIC"]
    },
    TRAINING: {
        path: "/training",
        page: Training,
        title: `${baseTitle}training`,
        roles: ["ROLE_PUBLIC"]
    },
    DISTRIBUTOR: {
        path: "/distributor",
        page: Distributor,
        title: `${baseTitle}distributor`,
        roles: ["ROLE_PUBLIC"]
    },
    STUDENT: {
        path: "/student",
        page: Student,
        title: `${baseTitle}student`,
        roles: ["ROLE_PUBLIC"]
    },
    ABOUT_US: {
        path: "/about-us",
        page: AboutUs,
        title: `${baseTitle}about us`,
        roles: ["ROLE_PUBLIC"]
    },
    LABNOTES: {
        path: "/labnotes",
        page: LabNotes,
        title: `${baseTitle}labnotes`,
        roles: ["ROLE_PUBLIC"]
    },
    APPERTFLOW: {
        path: "/appertflow",
        page: AppertFlow,
        title: `${baseTitle}appertflow`,
        roles: ["ROLE_PUBLIC"]
    },
    SUPPORT: {
        path: "/support",
        page: Support,
        title: `${baseTitle}support`,
        roles: ["ROLE_PUBLIC"]
    },
    SUPPORTCONTENT: {
        path: "/support-content",
        params: "/:typeSlug",
        page: SupportContent,
        title: `${baseTitle}support content`,
        roles: ["ROLE_PUBLIC"]
    }
}
