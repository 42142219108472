import React, {useEffect, useState} from "react";
import {apiGetPost} from "../../../services/User/post";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

export default function Block1(props) {

    const {i18n} = useTranslation()
    const {id} = useParams()
    const [state,setState] = useState()
    const [loading,setLoading] = useState(false)
    const {headerType} = props

    useEffect(() => {
        getPost()
    }, [])

    const getPost = () => {
        setLoading(true)
        apiGetPost({
            id,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            setLoading(false)
           setState(resp.data)
        }).catch(err => {
            setLoading(false)
        })
    }

    return (
        <div className="block1">
            {/* {state?.general?.isbn}
            {loading && (<>Espere unos segundos...</>)} */}
            <div className="block1-container">
                <div className="block1-title">
                    <h1>{headerType?.title}</h1>
                    <h2>{headerType?.subtitle}</h2>
                </div>
                <div className="block1-text">
                    <p>{headerType?.text}</p>
                </div>
            </div>
        </div>
    )
}
