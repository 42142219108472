import * as React from 'react';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import {Box, Container, Grid, IconButton, Toolbar, Typography, useMediaQuery, useTheme} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuOne from "./MenuPrimary";
import MenuAcount from "./MenuAccount";
import MenuLanguage from "./MenuLanguage";
import logo from "../../../assets/images/logo-small.svg";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1, transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen,
    }), ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
    '& .MuiDrawer-paper': {
        position: 'relative', whiteSpace: 'nowrap', width: drawerWidth, transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen,
        }), boxSizing: 'border-box', ...(!open && {
            overflowX: 'hidden', transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen,
            }), width: theme.spacing(7), [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}),);

const mdTheme = createTheme();

export default function Basic(props) {
    const {menu} = props

    const [open, setOpen] = React.useState(true)
    const toggleDrawer = () => {
        setOpen(!open)
    }

    const smUp = useMediaQuery(useTheme().breakpoints.up("md"))

    return (<ThemeProvider theme={mdTheme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar position="absolute" open={open} style={{boxShadow: "none", borderBottom: "1px solid #dbdcdb"}}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                            background: 'rgb(255, 255, 255)',
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="default"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px', ...(open && {display: 'none'}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>

                        <Typography
                            component="h1"
                            variant="h6"
                            color="default"
                            noWrap
                            sx={{flexGrow: 1, color: "black"}}
                        >
                            Dashboard
                        </Typography>

                        <MenuLanguage/>
                        <MenuAcount/>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1],
                            background: '#000'
                        }}
                    >

                        <img src={logo} style={{width: '90%', margin: 'auto'}} />

                        {!smUp && (
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon/>
                            </IconButton>
                        )}

                    </Toolbar>
                    <MenuOne menu={menu}/>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>
                    <Container sx={{mb: 4}}>
                        {props.children}
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>)
}
