import * as React from 'react';
import Img1 from '../../../assets/Uploads/support/shutterstock_682962715_BIBLIOGRAFIA_small.webp'
import Img2 from '../../../assets/Uploads/support/shutterstock_668022730_TUTORIALES_small.webp'
import Img3 from '../../../assets/Uploads/support/shutterstock_334586228_DESCARGAS.webp'
import Img4 from '../../../assets/Uploads/support/shutterstock_1935754660_FAQS_small.webp'
import Img5 from '../../../assets/Uploads/support/shutterstock_341144111_ENLACES_DE_INTERES.webp'
import Img6 from '../../../assets/Uploads/support/shutterstock_152639936_VIDEOS_small.webp'
import Vector from '../../../assets/Uploads/support/Vector.png'
import {Link} from "react-router-dom";
import {RouteUser} from '../../../routes/RouteUser'

export default function Block2() {

    return (
        <div className="block2">
            <div className="block2-container">
                <div className="block2-flex-div">
                    <div className="flex-img">
                        <img src={Img1} />
                    </div>
                    <div className="block2-flex-content">
                        <div className="flex-text">
                            <h3>Bibliografía</h3>
                            <p>Lista de libros recomendados por SciFood Studio.</p>
                        </div>
                        <div className="flex-access">
                            <Link to={`${RouteUser.SUPPORTCONTENT.path}/biografia`}>Acceder</Link>
                        </div>
                    </div>
                </div>
                <div className="block2-flex-div">
                    <div className="flex-img">
                        <img src={Img2} />
                    </div>
                    <div className="block2-flex-content">
                        <div className="flex-text">
                            <h3>Tutoriales</h3>
                            <p>Documentación y ejemplos para iniciarte en SciFood Studio.</p>
                        </div>
                        <div className="flex-access">
                            <Link to={`${RouteUser.SUPPORTCONTENT.path}/tutoriales`}>Acceder</Link>
                        </div>
                    </div>
                </div>
                <div className="block2-flex-div">
                    <div className="flex-img">
                        <img src={Img3} />
                    </div>
                    <div className="block2-flex-content">
                        <div className="flex-text">
                            <h3>Descargas</h3>
                            <p>Área privada para clientes. Recursos adicionales y utilidades.</p>
                        </div>
                        <div className="flex-access">
                            <Link to={`${RouteUser.SUPPORTCONTENT.path}/descargas`} style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                alignItems: "center"
                            }}><img src={Vector} style={{
                                width: "13.75px",
                                objectFit: "cover",
                                marginRight: "10px"
                            }} /> Acceder</Link>
                        </div>
                    </div>
                </div>
                <div className="block2-flex-div">
                    <div className="flex-img">
                        <img src={Img4} />
                    </div>
                    <div className="block2-flex-content">
                        <div className="flex-text">
                            <h3>FAQ</h3>
                            <p>Resuelve las dudas más frecuentes.</p>
                        </div>
                        <div className="flex-access">
                            <Link to={`${RouteUser.SUPPORTCONTENT.path}/faq`}>Acceder</Link>
                        </div>
                    </div>
                </div>
                <div className="block2-flex-div">
                    <div className="flex-img">
                        <img src={Img5} />
                    </div>
                    <div className="block2-flex-content">
                        <div className="flex-text">
                            <h3>Enlaces de interés</h3>
                            <p>Enlaces de interés en el campo de la bromatología.</p>
                        </div>
                        <div className="flex-access">
                            <Link to={`${RouteUser.SUPPORTCONTENT.path}/enlaces-de-interes`}>Acceder</Link>
                        </div>
                    </div>
                </div>
                <div className="block2-flex-div">
                    <div className="flex-img">
                        <img src={Img6} />
                    </div>
                    <div className="block2-flex-content">
                        <div className="flex-text">
                            <h3>Vídeos</h3>
                            <p>Aprende con ejemplos reales y videotutoriales.</p>
                        </div>
                        <div className="flex-access">
                            <Link to={`${RouteUser.SUPPORTCONTENT.path}/videos`}>Acceder</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
