import React from "react";
import {CircularProgress, Box} from "@mui/material";

export default function LoadingCircle(props) {

    let showIn = props.show || false
    return (
        <>
            {showIn && (
                <Box sx={{maxWidth: "50px", marginLeft: "auto", marginRight: "auto", marginTop: "50px", marginBottom: "50px"}}>
                    <CircularProgress sx={{ color: '#000' }}/>
                </Box>
            )}
        </>
    )
}
