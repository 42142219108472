import Block1 from "../../../components/User/Support/block1"
import Block2 from "../../../components/User/Support/block2"
import Block3 from "../../../components/User/Support/block3"
import {useEffect} from "react";
import ReactGA from "react-ga4";
export default function Support() {

    useEffect(() => {
        ReactGA.event({
            category: "Page",
            action: `Support`,
        });
    },[])

    return (
        <div className="support">
            <Block1 />
            <Block2 />
            <Block3 />
        </div>
    );
}
