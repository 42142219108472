import React, {useEffect, useState} from 'react';
import {EditorState} from 'draft-js';
import {stateToHTML} from "draft-js-export-html";
import {stateFromHTML} from "draft-js-import-html";
import {Editor} from 'react-draft-wysiwyg';

export default function EditorText(props) {

    const {contant, setContent} = props

    const [text, setText] = useState(EditorState.createEmpty())

    useEffect(() => {
        if (contant) {
            let textCheck = stateToHTML(text.getCurrentContent())
            if (contant !== textCheck) {
                const contentState = stateFromHTML(contant)
                setText(EditorState.createWithContent(contentState))
            }
        } else {
            setText(EditorState.createEmpty())
        }
    }, [contant])

    const onEditorStateChange = (newText) => {
        const blocks = stateToHTML(newText.getCurrentContent())
        setContent(blocks)

        setText(newText)
    }

    return (
        <div style={{background: '#fff', padding: 10}}>
            <Editor
                editorState={text}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                toolbarClassName="toolbar-class"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                    options: ['inline', 'blockType', 'link','history'],
                    // options: ['inline', 'blockType', 'link', 'image','history'],
                    inline: { inDropdown: false, options: ['bold', 'italic', 'underline', 'strikethrough']},
                    list: { inDropdown: true },
                    link: { inDropdown: false, options: ['link']},
                    image: { inDropdown: false },
                    history: { inDropdown: false },
                }}
            />
        </div>
    )
}
