import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

export function apiList(props) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.id) { query += `&id=${props.id}`; }
    if (props.author) { query += `&author=${props.author}`; }
    if (props.subcategory) { query += `&subcategory=${props.subcategory}`; }
    if (props.category) { query += `&category=${props.category}`; }
    if (props.type) { query += `&type=${props.type}`; }

    return axios.get(`${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/${props.lang}/admin/post/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}

export function apiCreate(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/${data.lang}/admin/post/create`, [data.state], config)
}

export function apiUpdate(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.put(`${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/${data.lang}/admin/post/update/${data.id}`, [data.state], config)
}

export function apiUpdateSeo(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.put(`${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/${data.lang}/admin/post/update-seo/${data.id}`, [data.trans], config)
}

export function apiUpdateContent(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.put(`${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/${data.lang}/admin/post/update-contant/${data.id}`, [data.trans], config)
}

export function apiGetPost(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/${data.lang}/admin/post/get-one/${data.id}`, config)
}
