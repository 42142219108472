import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

export function apiList(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let onlyShow = ""
    if (data.onlyShow) {
        onlyShow = `&only-show=${data.onlyShow}`
    }

    return axios.get(`${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/${data.lang}/admin/media/list?limit=${data.limit}&offset=${data.offset}${onlyShow}`, config)
}

export function apiUpload(data, lang) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/${lang}/admin/media/upload`, data, config)
}

export function apiGetOne(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/${data.lang}/admin/media/${data.id}`, config)
}

export function apiMediaDetail(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/${data.lang}/admin/media/update-details/${data.id}`, data.trans, config)
}

export function apiDelete(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.delete(`${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/${data.lang}/admin/media/delete/${data.id}`, config)
}

export function apiMediaOrder(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/${data.lang}/admin/media/reorder/${data.id}`, data.mediaId, config)
}
