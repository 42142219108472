import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {apiGetPost, apiUpdateSeo} from "../../../../services/Admin/post";
import InputText from "../../../../components/Inputs/InputTextAdmin";
import {Grid, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import Header from "./Header";
import LoadingBar from "../../../../components/LoadingBar";
import clone from "../../../../utils/clone";

export default function Seo() {
    const {id} = useParams()
    const {t} = useTranslation()
    const {i18n} = useTranslation()

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)

    const myRef = useRef(null)

    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        getPost()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Si no existe el idioma iniciamos el modelo. Es decir, montamos estructura
    useEffect(() => {
        if (!state.trans[state.langSelect]) {
            setState({...state, trans: {...state.trans, [state.langSelect]: clone(state.model)}})
        }
    }, [state.langSelect])

    const getPost = () => {
        setMsgError([])

        apiGetPost({
            id,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            setState({
                ...state,
                id,
                trans: resp.data?.seo?.trans,
                loading: false
            })
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setState({
                ...state,
                loading: false
            })
        })
    }

    const handleChangeTrans = (event) => {
        state['trans'][state.langSelect][event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        updatePost()
    }

    const updatePost = () => {
        apiUpdateSeo({
            id: id,
            trans: state?.trans,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    return (
        <>
            <div ref={myRef}></div>

            <Header
                state={state}
                setState={setState}
                id={id}
                trans={true}
                msgError={msgError}
                msgSuccess={msgSuccess}
            />

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{display: 'flex', flexDirection: 'column', borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
                        <LoadingBar loading={state.loading} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={3}>

                                    <Grid item xs={6}>
                                        <InputText
                                            label="Slug"
                                            name="slug"
                                            value={state?.trans?.[state.langSelect]?.slug || ""}
                                            onChange={handleChangeTrans}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputText
                                            label="Metatítulo"
                                            name="title"
                                            value={state?.trans?.[state.langSelect]?.title || ""}
                                            onChange={handleChangeTrans}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputText
                                            label="Descripción"
                                            name="description"
                                            rows={5}
                                            multiline={true}
                                            value={state?.trans?.[state.langSelect]?.description || ""}
                                            onChange={handleChangeTrans}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}>
                    <LoadingButton
                        onClick={handleSubmit}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        fullWidth
                    >
                        {id ? t('Modificar') : t('Crear')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        langSelect: props?.langSelect || "es",
        type: props?.type || 3,
        trans: [],
        model: {
            slug: "",
            title: "",
            description: "",
        },
        loading: true
    }
}
