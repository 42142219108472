import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import {apiCreate, apiGetUser, apiUpdate} from "../../../../services/Admin/user";
import InputText from "../../../../components/Inputs/InputTextAdmin";
import InputPassword from "../../../../components/Inputs/InputPassword";
import InputSwitch from "../../../../components/Inputs/InputSwitch";
import InputSelect from "../../../../components/Inputs/InputSelect";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {Button, Divider, Grid, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import {RouteUser} from "../../../../routes/RouteUser";
import {apiImpersonate} from "../../../../services/Auth/login";
import ButtonFile from "../../../../components/Inputs/ButtonFile";
import {apiDelete, apiUpload} from "../../../../services/Admin/media";
import LoadingBar from "../../../../components/LoadingBar";

export default function Edit() {
    const {id} = useParams()
    const history = useHistory()
    const {auth, login} = useAuth()
    const {t} = useTranslation()
    const {i18n} = useTranslation()
    const params = new URLSearchParams(window.location.search)

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [avatar, setAvatar] = useState([])
    const [roleDisabled, setRoleDisabled] = useState(false)

    const optionsRole = [{id: "ROLE_USER", name: t('user.roles.user')}, {id: "ROLE_ADMIN", name: t('user.roles.admin')}]

    const [checkedAccess, setCheckedAccess] = useState(true)

    const myRef = useRef(null)

    useEffect(() => {
        if (params.get("role-default") === 'admin') {
            setRoleDisabled(true)
            state['role'] = "ROLE_ADMIN"
            setState({...state})
        } else if (!id) {
            setRoleDisabled(true)
            state['role'] = "ROLE_USER"
            setState({...state})
        }

        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        if (id) {
            void getUser()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Esperamos respuesta de media, para obtener el avatar
        if (avatar.length > 0) {
            state["mediaId"] = avatar[0].id
            state["mediaUrl"] = avatar[0].url
            setState({...state})
        }
    }, [avatar]) // eslint-disable-line react-hooks/exhaustive-deps

    const getUser = () => {
        setMsgError([])
        setLoadingData(true)

        apiGetUser(id).then(resp => {
            setState(initialValues(resp.data?.data))
            setLoadingData(false)
            if (resp.data?.data?.isActive) {
                setCheckedAccess(true)
            } else {
                setCheckedAccess(false)
            }

        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleChangeChecked = (event) => {
        state[event.target.name] = event.target.checked
        setState({...state})
        if (event.target.checked) {
            setCheckedAccess(true)
        } else {
            setCheckedAccess(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        let error = validationSchema(state, id)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        if (id) {
            void updateUser()
        } else {
            void createUser()
        }
    }

    const updateUser = () => {
        apiUpdate(state).then(async resp => {
            if (state.file.length > 0) {
                await updateMedia(id, resp.data?.msg, false)
            }

            if (state.removeFile) {
                await handleRemoveFile()
            }

            setMsgSuccess(["Usuario modificado correctamente"])
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const createUser = () => {
        apiCreate(state).then(resp => {
            if (state.file.length > 0) {
                updateMedia(resp.data?.data?.id, resp.data?.msg, true)
            } else {
                history.push(RouteAdmin.USER_EDIT.path + `/${resp.data?.data?.id}?msg=Usuario creado correctamente`)
            }
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const updateMedia = (id, msg, reset) => {
        let f = new FormData()
        for (let value of state.file) {
            f.append("file[]", value.binary)
            f.append("idUser[]", id)
        }

        apiUpload(f).then(resp => {
            setLoading(false)
            myRef.current.scrollIntoView()
            if (reset) {
                setState({...state, file: []})
                history.push(RouteAdmin.USER_EDIT.path + `/${id}?msg=Usuario creado correctamente`)
            }
            setState({...state, file: []})
            getUser()
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const clickImpersonate = () => {
        setMsgError([])
        apiImpersonate(id).then(resp => {
            impersonate(resp.data?.token)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    const impersonate = async (token) => {
        await login({
            primaryUser: token,
            secondaryUser: auth?.secondaryUser,
        })
        history.push(RouteUser.ROOT.path);
    }

    const handleRemoveFile = () => {
        apiDelete({
            id: state.agreementId,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            state["agreement"] = ""
            state["removeFile"] = false
            state["file"] = []
            setState({...state})
            getUser()
        }).catch(err => {
            console.error(err)
            setLoading(false)
        })
    }

    const handleRemoveMedia = () => {
        state['removeFile'] = true
        setState({...state})
    }

    return (
        <>
            <div ref={myRef}></div>
            <HeaderPage title={t('user.account_config')} urlBack={RouteAdmin.USER_MANAGEMENT.path}/>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                        <LoadingBar loading={loadingData} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <h4 className="p-0 mt-0">{t('user.settings')}</h4>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} style={{paddingTop: "0px"}}>
                                        <InputText
                                            label={t('user.first_name')}
                                            required
                                            name="firstName"
                                            value={state?.firstName}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6} style={{paddingTop: "0px"}}>
                                        <InputText
                                            label={t('user.last_name')}
                                            required
                                            name="lastName"
                                            value={state?.lastName}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputText
                                            label={t('user.email')}
                                            required
                                            name="email"
                                            value={state?.email}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputText
                                            label={t('user.phone')}
                                            required={state.role === 'ROLE_USER' ? true : false}
                                            name="phone"
                                            value={state?.phone}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputText
                                            label={t('user.country')}
                                            required={state.role === 'ROLE_USER' ? true : false}
                                            name="country"
                                            value={state?.country}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputText
                                            label={t('user.address')}
                                            required={state.role === 'ROLE_USER' ? true : false}
                                            name="address"
                                            value={state?.address}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputText
                                            label={t('user.city')}
                                            required={state.role === 'ROLE_USER' ? true : false}
                                            name="city"
                                            value={state?.city}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputText
                                            label={t('user.postalCode')}
                                            required={state.role === 'ROLE_USER' ? true : false}
                                            name="postalCode"
                                            value={state?.postalCode}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>

                                    {!state?.agreement && (
                                        <Grid item xs={6}>
                                            {(state.file.length > 0) && (
                                                <Button
                                                    disabled={true}
                                                    fullWidth={true}
                                                    variant={"outlined"}
                                                    size="small"
                                                >Pendiente de guardar</Button>
                                            )}

                                            {(state.file.length === 0) && (
                                                <ButtonFile
                                                    label="Documentación adjunta"
                                                    name="file"
                                                    size="small"
                                                    onChange={handleChange}
                                                />
                                            )}
                                        </Grid>
                                    )}

                                    {state?.agreement && (
                                        <>
                                            <Grid item xs={6}>
                                                {!state.removeFile && (
                                                    <Button
                                                        fullWidth={true}
                                                        variant={"outlined"}
                                                        color="error"
                                                        size="small"
                                                        onClick={handleRemoveMedia}
                                                    >Eliminar</Button>
                                                )}
                                                {state.removeFile && (
                                                    <Button
                                                        disabled={true}
                                                        fullWidth={true}
                                                        variant={"outlined"}
                                                        color="error"
                                                        size="small"
                                                    >Pendiente de eliminación</Button>
                                                )}
                                            </Grid>
                                        </>
                                    )}

                                    {state?.agreement && (
                                        <Grid item xs={6} style={{paddingTop: 27}}>
                                            <a href={state?.agreement} target="_blank">Pulsa para visualizar la documentación</a>
                                        </Grid>
                                    )}

                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                        <LoadingBar loading={loadingData} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <h4 className="p-0 mt-0">Empresa</h4>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={3}>

                                    <Grid item xs={12} style={{paddingTop: "0px"}}>
                                        <InputText
                                            label="Empresa"
                                            required={state.role === 'ROLE_USER' ? true : false}
                                            name="company"
                                            value={state?.company}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputText
                                            label="NIF"
                                            required={state.role === 'ROLE_USER' ? true : false}
                                            name="nif"
                                            value={state?.nif}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                        <LoadingBar loading={loadingData} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <h4 className="p-0 mt-0">{t('user.security')}</h4>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} style={{paddingTop: "0px"}}>
                                        <InputSelect
                                            label={t('user.role')}
                                            name="role"
                                            required
                                            onChange={handleChange}
                                            disabled={roleDisabled}
                                            value={state?.role}
                                            options={optionsRole}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputPassword
                                            label={t('user.password')}
                                            name="password"
                                            value={state?.password}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputSwitch
                                            label={t('user.has_access')}
                                            name="isActive"
                                            checked={checkedAccess}
                                            onChange={handleChangeChecked}
                                        />
                                    </Grid>

                                    {id && (
                                        <Grid item xs={12}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                type="button"
                                                size="small"
                                                fullWidth
                                                onClick={clickImpersonate}
                                            >
                                                {t('user.impersonate')}
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}>
                    <LoadingButton
                        onClick={handleSubmit}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        fullWidth
                    >
                        {id ? t('Modificar') : t('Crear')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    )
        ;
}

function initialValues(props) {
    return {
        id: props?.id || "",
        firstName: props?.firstName || "",
        lastName: props?.lastName || "",
        email: props?.email || "",
        password: "",
        role: props?.role || "",
        phone: props?.phone || "",
        country: props?.country || "",
        address: props?.address || "",
        city: props?.city || "",
        postalCode: props?.postalCode || "",
        file: [],
        isActive: props?.isActive || true,
        agreement: props?.agreement || "",
        agreementId: props?.agreementId || null,
        removeFile: false,
        nif: props?.nif || "",
        company: props?.company || "",
    }
}

function validationSchema(schema, id) {

    let error = []

    if (!schema.firstName) {
        error.push("El nombre es obligatorio")
    }

    if (!schema.lastName) {
        error.push("El Apellido es obligatorio")
    }

    if (!schema.phone && schema.role === 'ROLE_USER') {
        error.push("El Teléfono es obligatorio")
    }

    if (!schema.country && schema.role === 'ROLE_USER') {
        error.push("El País es obligatorio")
    }

    if (!schema.address && schema.role === 'ROLE_USER') {
        error.push("La Dirección es obligatorio")
    }

    if (!schema.city && schema.role === 'ROLE_USER') {
        error.push("La Ciudad es obligatorio")
    }

    if (!schema.postalCode && schema.role === 'ROLE_USER') {
        error.push("El Código postal es obligatorio")
    }

    if (!schema.email && schema.role === 'ROLE_USER') {
        error.push("El Email es obligatorio")
    }

    if (!schema.role) {
        error.push("El Rol es obligatorio")
    }

    if (!schema.nif && schema.role === 'ROLE_USER') {
        error.push("El NIF es obligatorio")
    }

    if (!schema.company && schema.role === 'ROLE_USER') {
        error.push("La Empresa es obligatoria")
    }

    if (!schema.password && !id) {
        error.push("La Contraseña es obligatoria")
    }

    return error
}
