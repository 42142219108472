import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

export function apiList(props) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.id) { query += `&id=${props.id}`; }
    if (props.name) { query += `&name=${props.name}`; }
    if (props.isActive) { query += `&is-active=${props.isActive}`; }

    return axios.get(`${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/${props.lang}/admin/distributor/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}

export function apiCreate(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/${data.lang}/admin/distributor/create`, data.state, config)
}

export function apiUpdate(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.put(`${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/${data.lang}/admin/distributor/edit/${data.state.id}`, data.state, config)
}

export function apiGetOne(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/${data.lang}/admin/distributor/get-one/${data.id}`, config)
}
