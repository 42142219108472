import {useEffect} from "react";
import ReactGA from "react-ga4";
export default function LegalConditions() {

        useEffect(() => {
                ReactGA.event({
                        category: "Page",
                        action: `LegalConditions`,
                });
        },[])

    return (
        <div className="legal-conditions">
            <h1>CONDICIONES DE USO DEL PORTAL “SCIFOODSTUDIO.NET”</h1>
            <p>La utilización del sitio web de GEMINA I MÁS D, S.L. www.scifoodstudio.net, en adelante “GÉMINA” o “la empresa” de forma indistinta, la de los subdominios y/o cualquier directorio o página relacionada con el mismo (en adelante conjuntamente denominados el "Sitio" o “el Portal” indistintamente) queda sometida tanto a las presentes Condiciones Generales de Uso, como a las restantes Políticas que, en su caso, puedan regir la utilización de determinados servicios ofertados en el referido Portal. Así, con carácter previo a la utilización de dichos servicios, el Usuario (en adelante, de forma indistinta, “el usuario”) habrá de leer atentamente tanto este Aviso Legal y Condiciones de Uso como, en su caso, las correspondientes Políticas de Privacidad y Cookies que pudieran resultar de aplicación a la oferta servicios o venta de productos que se realice en el Portal.</p>
            <p>La utilización del Sitio se encuentra sometida igualmente a todos los avisos, normativa, reglamentos de uso e instrucciones, que puestos en conocimiento del Usuario por GEMINA sustituyan, completen y/o modifiquen las presentes Condiciones de Uso. Por el mero uso del Sitio o de cualquiera de los sitios incluidos en la página web, el Usuario manifiesta su aceptación sin reservas de las presentes Condiciones Generales de Uso.</p>
            <p>Por lo tanto, si las consideraciones detalladas en este Aviso Legal y Condiciones de Uso no son de su conformidad, rogamos no haga uso del Sitio, puesto que, en caso contrario, cualquier uso que haga del mismo o de los servicios y contenidos en él implicará la aceptación de los términos legales recogidos en el sitio web GÉMINA.</p>
            <p>GÉMINA se reserva el derecho a realizar cambios en el Sitio Web sin previo aviso, con el objeto de actualizar, ampliar, corregir o modificar los contenidos del Sitio o de su diseño. Los contenidos y servicios del Sitio se actualizan periódicamente. Debido a que la actualización de la información no es inmediata, le sugerimos que compruebe siempre la vigencia y exactitud de la información, servicios y contenidos recogidos en el Sitio en el momento de su consulta. Puede asegurarse de este extremo haciendo click en el botón de “actualizar” de su navegador o pulsando la tecla F5 de su teclado. Asimismo, las condiciones y términos que se recogen en el presente Aviso Legal pueden variar, por lo que le invitamos a que revise estos términos cuando visite de nuevo el Sitio.</p>
            <p>El acceso a algunas de las secciones o apartados de esta página web le puede redireccionar a otras páginas de GÉMINA, en las que encontrará las correspondientes Condiciones Generales de Uso y Política de Privacidad, todas ellas en línea con las contenidas en la presente página e igualmente vinculantes para el Usuario.</p>
            <p className="text-bold">Condición de usuario</p>
            <p>La utilización del Sitio atribuye la condición de Usuario del mismo (en adelante, “el Usuario") e implica la aceptación plena y sin reservas de la totalidad de las disposiciones incluidas tanto en el presente Aviso Legal y Condiciones de Uso como en la Política de Privacidad y Cookies y cualquier otra política que regule el uso del Portal. El portal, por su naturaleza y finalidad se dirige a un público empresarial o profesional.</p>
            <p className="text-bold">Responsabilidades del usuario</p>
            <p>El Usuario se compromete a utilizar los Servicios de GÉMINA de acuerdo con los términos expresados en el presente Aviso Legal y Condiciones de Uso, así como cualquier otra norma o política establecida en el Portal, siendo responsable de su correcto uso.</p>
            <p>El Usuario se obliga a no utilizar el Sitio GÉMINA ni cualquiera de sus contenidos o partes integrantes (textos, meta etiquetas, código fuente, etc.), diseño o estructura para la prestación de servicios, la realización de actividades publicitarias o de explotación comercial a través de cualquier tipo de medio o red de comunicación, sin contar con la previa autorización expresa y por escrito de la empresa.</p>
            <p className="text-bold">Veracidad de los datos proporcionados por el Usuario</p>
            <p>Para tener acceso a los productos y/o servicios ofertados en la web se requiere un registro previo del Usuario. En tal caso, el Usuario deberá darse de alta como tal, debiendo para ello leer y aceptar expresamente, con carácter previo todas las políticas legales del sitio. En todo caso, dichas políticas serán aceptadas previamente a solicitar cualquier la prestación de cualquier servicio o venta de cualquier bien.</p>
            <p>Toda la información que facilite el Usuario deberá ser veraz. A tal efecto, el Usuario garantiza la autenticidad de todos aquellos datos que comunique como consecuencia de la cumplimentación de los formularios necesarios para el registro y acceso a los servicios ofertados por GÉMINA. Será asimismo responsabilidad del Usuario mantener toda la información facilitada a GÉMINA permanentemente actualizada de forma que responda, en cada momento, a su situación real. En cualquier caso, el Usuario será el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause a la empresa por la información que facilite, y en particular, será responsable de la imposibilidad de poder llevar a cabo la correcta prestación del servicio si ello se debe a la falsedad, inexactitud o insuficiencia de la información o datos facilitados para la realización de las gestiones necesarias a llevar a cabo por GÉMINA.</p>
            <p>El Usuario o cualquier tercero que envíe comunicaciones a este sitio web o a sus propietarios, será responsable del contenido de éstas, también en lo que se refiere a su veracidad y precisión, no haciéndose por tanto responsable la empresa de la información y contenidos introducidos por terceros. No obstante, lo anterior, y en cumplimiento de lo dispuesto en el art. 11, 16 y concordantes de la Ley 34/2002, de Servicios de la Sociedad de la Información y del Comercio Electrónico, GÉMINA se pone a disposición de todos los usuarios, autoridades y fuerzas de seguridad para colaborar de forma activa en la retirada o en su caso bloqueo de todos aquellos contenidos que pudieran afectar o contravenir la legislación nacional, o internacional, derechos de terceros o la moral y el orden público. En caso de que el usuario considere que existe en el sitio web algún contenido que pudiera ser susceptible de esta clasificación, se ruega lo notifique de forma inmediata al propietario del sitio web.</p>
            <p className="text-bold">Uso de servicios de mensajería, y sistemas de integración de redes sociales</p>
            <p>GÉMINA no se hace responsable de los contenidos que, vulnerando las presentes Condiciones Generales de Uso y de cualesquiera otras que regulen o puedan regular cualesquiera de los servicios ofrecidos en la página web de la empresa, pudieran enviarse, siendo el Usuario el único responsable de la veracidad y licitud de los mismos.</p>
            <p>GÉMINA no se responsabiliza del uso que pueda darse ni de los contenidos que puedan ser enviados y/o visualizados a través de aplicaciones como sistemas de mensajería instantánea (chat o similares), plugins o widgets pertenecientes a redes sociales u otros proveedores de servicios de sociedad de la información. Ninguna de las informaciones y/o contenidos que puedan ser introducidos y/o visualizados a través de estas aplicaciones podrá ser entendido como contenido de GÉMINA ni que GÉMINA tenga responsabilidad alguna sobre los mismos.</p>
            <p>No obstante lo anterior, GÉMINA se reserva el derecho de retirar el acceso a las referidas aplicaciones y recursos en caso de tener conocimiento fehaciente de un uso indebido de los mismos, entendiéndose por tal la introducción de contenidos ilegales o contrarios a la moral y al orden público, así como en caso de hacerse en uso de la misma que sea o pueda ser perjudicial para la imagen de la compañía, de conformidad con lo establecido en la Ley 34/2002, de Servicios de la Sociedad de la Información y del Comercio Electrónico.</p>
            <p className="text-bold">Uso de los contenidos del sitio</p>
            <p>El Usuario se obliga a no utilizar el Portal de GÉMINA ni los servicios ofertados en o a través del mismo para la realización de actividades contrarias a las leyes, a la moral, al orden público, lesivas de los derechos e intereses de terceros o que de cualquier otra forma puedan dañar, inutilizar, sobrecargar, deteriorar o impedir la normal utilización del Sitio www.scifoodstudio.es o sus dominios asociados.</p>
            <p>El Usuario que actúe contra la imagen, buen nombre o reputación de GÉMINA, así como quien utilice ilícita o fraudulentamente los diseños, logos, marcas o contenidos del Sitio y/o viole en cualquier forma los derechos de propiedad intelectual e industrial del Sitio o de los contenidos y servicios de la misma, será responsable frente a GÉMINA de su actuación. A los efectos aquí previstos se entenderá por contenidos, sin que esta enumeración tenga carácter limitativo, los textos, fotografías, gráficos, imágenes, iconos, tecnología, software, links y demás contenidos audiovisuales o sonoros, así como su diseño gráfico y códigos fuente. En particular, el Usuario se compromete a abstenerse de:</p>
            <ul>
                <li>Reproducir, copiar, distribuir, poner a disposición o de cualquier otra forma comunicar públicamente, transformar o modificar los Contenidos integrados en GÉMINA, a menos que se cuente con la autorización previa y por escrito del titular de los correspondientes derechos o ello resulte legalmente permitido;</li>
                <li>Suprimir, manipular o de cualquier forma alterar el ¨copyright¨ y cualquier otro dato identificativo de la reserva de derechos de GÉMINA o de su titular (GEMINA I MÁS D, S.L.).</li>
            </ul>
            <p className="text-bold">Derechos de propiedad intelectual y de propiedad industrial</p>
            <p>Tanto el diseño del Sitio y su código fuente, como la totalidad de los contenidos, logos, marcas, nombres comerciales y demás signos distintivos que aparecen en el mismo, pertenecen a GEMINA I MÁS D, S.L. la cual es titular y propietaria de www.scifoodstudio.net y están protegidos por los correspondientes derechos de propiedad intelectual e industrial. Igualmente están protegidos por los correspondientes derechos de propiedad intelectual e industrial las imágenes, logos, textos y melodías, etc. contenidos en el servidor de GÉMINA. En ningún momento podrá entenderse que el uso o acceso al Sitio GÉMINA y/o a los servicios ofertados en el mismo atribuyen al Usuario derecho alguno sobre las citadas marcas, nombres comerciales y/o signos distintivos.</p>
            <p>Su uso, reproducción, distribución, comunicación pública, transformación o cualquier otra actividad similar o análoga, queda totalmente prohibida salvo que medie expresa autorización previa y por escrito de GEMINA I MÁS D, S.L. La licencia de uso de cualquier contenido de este Sitio otorgada al usuario se limita a la descarga por parte del usuario de dicho contenido para la prestación de los servicios provistos por EMPRESA.</p>
            <p>La marca nacional GÉMINA es titularidad de GÉMINA PROCESOS ALIMENTARIOS, S.L. mediante registro tramitado ante la Oficina Española de Patentes con número M-3016645.</p>
            <p>Las marcas nacionales SciFoodStudio, AppertFlow y LabNotes son titularidad de GÉMINA I MÁS D, SL mediante registros tramitados ante la Oficina de Propiedad Intelectual de la Unión Europea con números No. 018497552, No. 018497554 y No. 018498760 respectivamente.</p>
            <p>GÉMINA declara su respeto a los derechos de propiedad intelectual e industrial de terceros; por ello, si considera que este sitio pudiera estar violando sus derechos, rogamos se ponga en contacto con la empresa en la siguiente dirección de e-mail gemina@gemina.es</p>
            <p className="text-bold">Frames</p>
            <p>GÉMINA prohíbe expresamente la realización de ¨framings¨ o la utilización por parte de terceros de cualesquiera otros mecanismos que alteren el diseño, configuración original o contenidos de su Sitio.</p>
            <p className="text-bold">Links o Hiperenlaces</p>
            <p>GÉMINA puede facilitar el acceso a otras páginas web que considere que pueden ser de su interés. El objetivo de dichos enlaces es únicamente facilitarle la búsqueda de los recursos que le puedan interesar a través de Internet. No obstante, algunas de dichas páginas no pertenecen a GÉMINA ni se hace una revisión de sus contenidos, por lo que en ningún momento se podrá considerar a GÉMINA como responsable de las mismas, del funcionamiento de la página enlazada o de los posibles daños que puedan derivarse del acceso o uso de la misma. En este sentido, el Usuario será responsable de la lectura y observancia de los términos legales y condiciones de uso de las citadas páginas o servicios.</p>
            <p>No se permitirá el enlace de ninguna página web o de una dirección de correo electrónico al Sitio GÉMINA, salvo con la autorización expresa, previa y por escrito de GÉMINA. Adicionalmente, y salvo que se establezca lo contrario por GÉMINA, dichos enlaces deberán respetar las siguientes condiciones:</p>
            <ul>
                <li>únicamente podrán realizarse enlaces con la “Home Page” o página principal de esta web;</li>
                <li>el establecimiento del enlace no supondrá ningún tipo de acuerdo, contrato, patrocinio ni recomendación por parte de GÉMINA de la página que realiza el enlace salvo que así se derive de la correspondiente relación contractual entre las partes.</li>
            </ul>
            <p>En cualquier momento, GÉMINA podrá retirar la autorización mencionada en el párrafo anterior, sin necesidad de alegar causa ni contraprestación alguna. En tal caso, la página que haya realizado el enlace deberá proceder a su inmediata supresión, tan pronto como reciba la comunicación, que podrá ser realizada a través de correo electrónico, de la revocación de la autorización por parte de GÉMINA.</p>
            <p className="text-bold">Responsabilidades de EMPRESA</p>
            <p>A) Uso incorrecto del Sitio</p>
            <p>GEMINA I MÁS D S.L. ha creado el Sitio www.scifoodstudio.net para la difusión de su actividad y para facilitar la prestación de sus servicios y oferta de bienes, pero no puede controlar la utilización del mismo de forma distinta a la prevista en el presente Aviso Legal y Condiciones de Uso; por tanto el acceso al Sitio y el uso correcto de la información contenida en el mismo son responsabilidad de quien realiza estas acciones, no siendo responsable GÉMINA por el uso incorrecto, ilícito o negligente que del mismo pudiere hacer el Usuario, ni del conocimiento que puedan tener terceros no autorizados de la clase, condiciones, características y circunstancias del uso que los Usuarios hacen del Sitio y de los servicios.</p>
            <p>GÉMINA no será responsable de los perjuicios de cualquier naturaleza que puedan irrogarse como consecuencia de la suplantación de la personalidad de un tercero efectuada por un Usuario en cualquier clase de comunicación o servicio realizada a través del Sitio.</p>
            <p>B) Utilización de los contenidos</p>
            <p>GÉMINA facilita todos los contenidos de su Sitio de buena fe y realizará sus mejores esfuerzos para que los mismos estén permanentemente actualizados y vigentes; no obstante, GÉMINA no puede asumir responsabilidad alguna respecto al uso o al acceso que realicen los Usuarios fuera del ámbito específico al que se dirige el Sitio, recayendo la responsabilidad final sobre el Usuario.</p>
            <p>C) Virus</p>
            <p>GÉMINA se compromete, en el marco de la diligencia debida en el tráfico mercantil por un ordenado comerciante, a aplicar todas las medidas necesarias para intentar garantizar al Usuario la ausencia de virus, gusanos, caballos de Troya y elementos similares en su Sitio. No obstante, estas medidas no son infalibles y, por ello, no puede asegurar totalmente la ausencia de dichos elementos nocivos. En consecuencia, GÉMINA no será responsable de los daños que los mismos pudieran producir al Usuario. Por todo lo anterior, se recomienda al usuario la instalación en sus terminales informáticos de las medidas de protección exigibles tales como programas antivirus, cortafuegos, correcta actualización del sistema operativo, etc.</p>
            <p>D) Fallos tecnológicos</p>
            <p>GÉMINA ha concluido todos los contratos necesarios para la continuidad de su Sitio y realizará sus mejores esfuerzos para que el mismo no sufra interrupciones, pero no puede garantizar la ausencia de fallos tecnológicos, ni la permanente disponibilidad del Sitio y de los servicios contenidos en él y, en consecuencia, no asume responsabilidad alguna por los daños y perjuicios que puedan generarse por la falta de disponibilidad y por los fallos en el acceso ocasionados por desconexiones, averías, sobrecargas, caídas de la red no imputables, causas relacionadas con terceros ajenos a GÉMINA o cualquier otra causa de fuerza mayor.</p>
            <p>E) Tareas de mantenimiento</p>
            <p>En el supuesto de que el Portal de GÉMINA sea objeto de tareas de mantenimiento se podrá comunicar tal circunstancia con antelación al Usuario así como el periodo previsto aproximado de duración de dichas tareas. Dicha información podrá ser facilitada a través de un aviso en la propia página web o cualquier otro medio que se estime adecuado a tal finalidad siendo plenamente válido el correo electrónico facilitado por el Usuario.</p>
            <p className="text-bold">Acciones legales</p>
            <p>GÉMINA se reserva con carácter general el derecho de emprender las acciones legales y solicitar las indemnizaciones que en defensa de sus legítimos derechos e intereses le confiere la legislación española, y en particular la relativa a Competencia Desleal, Propiedad Intelectual e Industrial y demás normativa relacionada.</p>
            <p className="text-bold">Duración y terminación</p>
            <p>La venta de productos y prestación de los servicios de GÉMINA tienen, en principio, una duración indefinida. GÉMINA podrá, no obstante, dar por terminada, modificar o suspender la venta de productos o prestación del servicio/s del Sitio en cualquier momento, sin perjuicio de lo que se hubiere dispuesto al respecto en las correspondientes condiciones legales. A tal efecto, GÉMINA podrá comunicar dicha circunstancia en la pantalla de acceso al servicio con un preaviso de tres (3) días.</p>
            <p className="text-bold">Ley aplicable y jurisdicción</p>
            <p>La ley aplicable en caso de disputa o conflicto de interpretación de los términos que conforman este Aviso Legal, así como cualquier cuestión relacionada con los productos y/o servicios ofertados en el presente Sitio, será la ley española. Para la resolución de cualquier conflicto que pueda surgir con ocasión de la visita al Sitio o del uso de los servicios que en él se puedan ofertar, GÉMINA y el Usuario acuerdan someterse a los Jueces y Tribunales de la Ciudad de Murcia (España).</p>
            <p>GÉMINA se reserva, asimismo, el derecho de modificar unilateralmente, en cualquier momento y sin previo aviso, la presentación y condiciones del Sitio, así como los servicios del mismo y las condiciones requeridas para su utilización.</p>
        </div>
    );
}
