import * as React from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {RouteUser} from '../../../routes/RouteUser'

export default function Block3() {

    return (
        <div className="block3">
            <div className="block3-text-bottom">
                <h4>Solicita una demostración gratuita de Scifood Studio, sin compromiso</h4>
                <Link href={RouteUser.DEMO_REQUEST.path} underline="none" color="inherit"><Button className="solicitar-button">Solicitar Demo</Button></Link>
            </div>
        </div>
    )
}
