import {BASE_PATH, BASE_PATH_EXTENSION, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH}${BASE_PATH_EXTENSION}`;
const config = getToken() ? {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}} : {headers: { }};

export function apiGetPost(data) {

    let query = "";
    if (data.subcategoryId){
        query = `?subcategory-id=${data.subcategoryId}`
    }
    return axios.get(`${baseUrl}/${data.lang}/user/post/list${query}`, config)
}
