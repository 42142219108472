import React from "react";
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";

/**
 * Muestra un selector
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **value** *string*
 * - **options** *object*
 * - **onChange** *function*
 * - **multiple** *bool*
 * - **disabled** *bool*
 *
 * ## EJ
 * options: [{id: 1, name: "Aaaa"}, {id: 2, name: "Bbbbb"}]
 */
export default function InputSelect(props) {

    const {label, name, value, options, onChange, required, multiple, disabled, labelOnOption} = props

    const labelOnOptionIn = labelOnOption || false

    return (
        <FormControl fullWidth size={"small"} required={required}>
            {!labelOnOptionIn &&
                <InputLabel id="select-label" shrink={value !== null && value !== ""} >
                    {label}
                </InputLabel>}
            {labelOnOptionIn &&
              <Select
                  value={value}
                  name={name}
                  required={required}
                  disabled={disabled}
                  displayEmpty
                  multiple={multiple}
                  onChange={onChange}
                  inputProps={{ 'aria-label': 'Without label' }}
                  renderValue={(value) => {
                    if (!value) {
                      return <em>{label}</em>;
                    }

                    return value;
                  }}
                  >
                  <MenuItem disabled value=""><em>{label}</em></MenuItem>
                  {options.map((option, key) => {
                      return (<MenuItem key={key} value={option.id}>{option.name}</MenuItem>)
                  })}
              </Select>
            }
            {!labelOnOptionIn &&
              <Select
                  input={<OutlinedInput notched={value !== null && value !== ""} label={label} />}
                  labelId="select-label"
                  size="small"
                  value={value}
                  name={name}
                  required={required}
                  disabled={disabled}
                  displayEmpty={value !== null && value !== ""}
                  multiple={multiple}
                  onChange={onChange}
                  inputProps={{ 'aria-label': 'Without label' }}

              ><
                  MenuItem disabled value=""><span style={{color: "#828384"}}>Selecciona un elemento</span></MenuItem>
                {options.map((option, key) => {
                    return (<MenuItem key={key} value={option.id}>{option.name}</MenuItem>)
                })}
              </Select>
            }
        </FormControl>
    )
}
