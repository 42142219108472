import React, {useEffect, useRef, useState} from "react";
import {Button} from "@mui/material";
import {LoadingButton} from "@mui/lab";

/**
 * Muestra un botón para subir ficheros
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **onChange** *function*
 * - **required** *bool*
 * - **multiple** *bool*
 * - **loading** *bool*
 * - **accept** *string*
 *
 * ## RETURN
 * - **multiple == true**
 *
 * {target: {name: name, value: [{binary: binary, base64: base64}]}}
 * - **multiple == false**
 *
 * {target: {name: name, value: {binary: binary, base64: base64}}}
 */
export default function ButtonFile(props) {

    const {label, name, onChange, required, multiple, loading, accept} = props

    const inputFile = useRef(null)
    const [valueFile, setValueFile] = useState({})
    const [processed, setProcessed] = useState(0)
    const [fileLength, setFileLength] = useState(0)

    const handleClick = () => {
        inputFile.current.click()
    }

    useEffect(() => {
        if (valueFile.length > 0 && (processed == fileLength)) {
            onChange({target: {name: name, value: valueFile}})
        }
    }, [valueFile, processed, fileLength])

    const handleChange = (event) => {
        setProcessed(0)
        setFileLength(event.target.files.length)

        if (multiple) {
            multipleFile(event.target.files, event.currentTarget)
        } else {
            unMultipleFile(event.target.files, event.currentTarget)
        }
    }

    const unMultipleFile = (files, currentTarget) => {
        // Export binary
        let value = [{binary: files.item(0), base64: null}]

        // Export Base64
        let prosBase64 = 0
        let reader = new FileReader()
        let file = currentTarget.files[0]
        reader.readAsDataURL(file)
        reader.onloadend = () => {
            value[0].base64 = reader.result
            prosBase64 = prosBase64 + 1
            setProcessed(prosBase64)
        }

        setValueFile(value)
    }

    const multipleFile = (files, currentTarget) => {
        let value = []

        // Export binary
        for (let i = 0; i < files.length; i++) {
            value.push({binary: files.item(i), base64: null})
        }

        // Export Base64
        let prosBase64 = 0
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader()
            let file = currentTarget.files[i]
            reader.readAsDataURL(file)
            reader.onloadend = () => {
                value[i].base64 = reader.result
                prosBase64 = prosBase64 + 1
                setProcessed(prosBase64)
            }
        }

        setValueFile(value)
    }

    return (<>
            <LoadingButton
                type="button"
                loading={loading}
                disabled={loading}
                loadingPosition="start"
                variant="outlined"
                size="small"
                fullWidth
                onClick={handleClick}
                sx={{mt: 1, mb: 2}}
            >
                {label}
            </LoadingButton>

            <input
                type="file"
                onChange={handleChange}
                name={name}
                multiple={multiple}
                style={{display: 'none'}}
                className={"d-none"}
                required={required}
                ref={inputFile}
                accept={accept}
            />
        </>

    )

}
