import React from "react";

export default  function ContentLinks(props) {
    const {item, headerType} = props;

    return (
        <>
            <div className={headerType.classContent}>
                <a href={item.url} target="_blank">{item.url}</a>
                <p>{item.description}</p>
            </div>
        </>
    )
}
