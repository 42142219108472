import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {apiCreate, apiGetPost, apiUpdate} from "../../../../services/Admin/post";
import InputText from "../../../../components/Inputs/InputTextAdmin";
import {Grid, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import Divider from "@mui/material/Divider";
import Header from "./Header";
import LoadingBar from "../../../../components/LoadingBar";
import InputSwitch from "../../../../components/Inputs/InputSwitch";
import {apiList as apiListType} from "../../../../services/Admin/type";
import {apiList as apiListCategory} from "../../../../services/Admin/category";
import {apiList as apiListSubcategory} from "../../../../services/Admin/subcategory";
import clone from "../../../../utils/clone";
import InputAutocomplete from "../../../../components/Inputs/InputAutocomplete";
import InputAutocompleteMultiple from "../../../../components/Inputs/InputAutocompleteMultiple";

export default function General() {
    const {id} = useParams()
    const history = useHistory()
    const {t} = useTranslation()
    const {i18n} = useTranslation()
    const params = new URLSearchParams(window.location.search)

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [optionsType, setOptionsType] = useState([])
    const [optionsCategory, setOptionsCategory] = useState([])
    const [optionsSubcategory, setOptionsSubcategory] = useState([])
    const [checkedIsActive, setCheckedIsActive] = useState(true)
    const [checkedIsVisible, setCheckedIsVisible] = useState(true)

    const myRef = useRef(null)

    useEffect(() => {
        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, [])

    // Filtros de category
    useEffect(() => {
        getSelectorCategory()
    }, [state.items.type])

    // Filtros de categories
    useEffect(() => {
        getSelectorSubcategory()
    }, [state.items.categories])

    // Si no existe el idioma iniciamos el modelo. Es decir, montamos estructura
    useEffect(() => {
        if (!state.trans[state.langSelect]) {
            setState({...state, trans: {...state.trans, [state.langSelect]: clone(state.model)}})
        }
    }, [state.langSelect])

    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        getSelectorType()
        if (id) {
            void getPost()
            getSelectorCategory()
            getSelectorSubcategory()
        } else {
            if (!state.trans[state.langSelect]) {
                setState({...state, loading: false, trans: {...state.trans, [state.langSelect]: clone(state.model)}})
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getPost = () => {
        setMsgError([])

        apiGetPost({
            id,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            setState({
                ...state,
                trans: resp.data?.general?.trans,
                items: {
                    author: resp.data?.general?.items?.author,
                    isbn: resp.data?.general?.items?.isbn,
                    url: resp.data?.general?.items?.url,
                    isActive: resp.data?.general?.items?.isActive,
                    isVisible: resp.data?.general?.items?.isVisible,
                    isPublic: resp.data?.general?.items?.isPublic,
                    type: resp.data?.general?.items?.type,
                    categories: resp.data?.general?.items?.categories,
                    subcategories: resp.data?.general?.items?.subcategories,
                },
                loading: false
            })
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setState({
                ...state,
                loading: false
            })
        })
    }

    const getSelectorType = () => {
        apiListType({
            lang: i18n.resolvedLanguage,
            limit: 1000,
            offset: state.offset ?? 0,
            field: 'name',
            sort: 'desc',
            isActive: 1
        }).then(resp => {
            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc
                }
            })
            setOptionsType(temp)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    const getSelectorCategory = () => {
        apiListCategory({
            lang: i18n.resolvedLanguage,
            limit: 1000,
            offset: state.offset ?? 0,
            field: 'name',
            sort: 'desc',
            type: state.items?.type ?? null,
            isActive: 1
        }).then(resp => {
            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc
                }
            })
            setOptionsCategory(temp)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    // Comprobamos si existe el id en el array de categorías
    useEffect(() => {
        state.items.categories.forEach(categorySelect => {
            let exist = false
            optionsCategory.forEach(list => {
                if (categorySelect === list.id) {
                    exist = true
                }
            })
            if (!exist){
                state["items"]["categories"] = []
                setState({...state})
            }
        })
    }, [optionsCategory])

    // Comprobamos si existe el id en el array de subcategorias
    useEffect(() => {
        state.items.subcategories.forEach(subcategoriesSelect => {
            let exist = false
            optionsSubcategory.forEach(list => {
                if (subcategoriesSelect === list.id) {
                    exist = true
                }
            })
            if (!exist){
                state["items"]["subcategories"] = []
                setState({...state})
            }
        })
    }, [optionsSubcategory])

    const getSelectorSubcategory = () => {
        if (state.category) { params.category = state.category }

        apiListSubcategory({
            lang: i18n.resolvedLanguage,
            limit: 1000,
            offset: state.offset ?? 0,
            field: 'name',
            sort: 'desc',
            category:  state.items?.categories ?? null,
            isActive: 1
        }).then(resp => {
            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc
                }
            })
            setOptionsSubcategory(temp)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    const handleChangeTrans = (event) => {
        state['trans'][state.langSelect][event.target.name] = event.target.value
        setState({...state})
    }

    const handleChange = (event) => {
        state["items"][event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        if (id) {
            void updatePost()
        } else {
            void createPost()
        }
    }

    const updatePost = () => {
        apiUpdate({
            id,
            state,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const createPost = () => {
        apiCreate({
            state,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            history.push(`${RouteAdmin.POST_EDIT_GENERAL.path}/${resp.data?.id}?msg=${resp.data?.msg}`)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const handleChangeChecked = (event) => {
        state["items"][event.target.name] = event.target.checked
        setState({...state})
        if (event.target.checked) {
            if (event.target.name === "isActive"){
                setCheckedIsActive(true)
            } else {
                setCheckedIsVisible(true)
            }
        } else {
            if (event.target.name === "isActive"){
                setCheckedIsActive(false)
            } else {
                setCheckedIsVisible(false)
            }
        }
    }

    return (
        <>
            <div ref={myRef}></div>

            <Header
                state={state}
                setState={setState}
                id={id}
                msgError={msgError}
                msgSuccess={msgSuccess}
            />

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{display: 'flex', flexDirection: 'column', borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
                        <LoadingBar loading={state.loading} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={3}>

                                    <Grid item xs={6}></Grid>
                                    <Grid item xs={6}>
                                        <div style={{float: 'right'}}>
                                            <InputSwitch
                                                label="Public"
                                                name="isPublic"
                                                checked={state?.items?.isPublic}
                                                onChange={handleChangeChecked}
                                            />
                                            <InputSwitch
                                                label="Activo"
                                                name="isActive"
                                                checked={state?.items?.isActive}
                                                onChange={handleChangeChecked}
                                            />
                                            <InputSwitch
                                                label="Visible"
                                                name="isVisible"
                                                checked={state?.items?.isVisible}
                                                onChange={handleChangeChecked}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputText
                                            label="Nombre"
                                            name="name"
                                            value={state?.trans?.[state.langSelect]?.name}
                                            onChange={handleChangeTrans}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputText
                                            label="Autor"
                                            name="author"
                                            value={state?.items?.author}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputAutocomplete
                                            label="Tipo"
                                            name="type"
                                            required
                                            onChange={handleChange}
                                            value={state?.items?.type}
                                            options={optionsType}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputAutocompleteMultiple
                                            label="Categoria"
                                            name="categories"
                                            onChange={handleChange}
                                            value={state?.items?.categories}
                                            options={optionsCategory}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputAutocompleteMultiple
                                            label="Subcategoria"
                                            name="subcategories"
                                            onChange={handleChange}
                                            value={state?.items?.subcategories}
                                            options={optionsSubcategory}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputText
                                            label="URL"
                                            name="url"
                                            value={state?.items?.url}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputText
                                            label="ISBN"
                                            name="isbn"
                                            value={state?.items?.isbn}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputText
                                            label="Descripcion"
                                            name="description"
                                            rows={5}
                                            multiline={true}
                                            value={state?.trans?.[state.langSelect]?.description}
                                            onChange={handleChangeTrans}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}>
                    <LoadingButton
                        onClick={handleSubmit}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        fullWidth
                    >
                        {id ? t('Modificar') : t('Crear')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        langSelect: props?.langSelect || "es",
        type: props?.type || 0,
        items: {
            author: props?.author || "",
            isbn: props?.isbn || "",
            type: props?.type || null,
            categories: props?.categories || [],
            subcategories: props?.subcategories || [],
            isActive: props?.isActive || true,
            isVisible: props?.isVisible || true,
            isPublic: props?.isPublic || true,
            url: props?.url || "",
        },
        trans: {},
        model: {
            name: "",
            description: "",
        },
        loading: true
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.items.type) {
        error.push("El tipo es obligatorio.")
    }

    if (!schema.trans?.es?.name) {
        error.push("El nombre es obligatorio, en español.")
    }

    return error
}
