import React from "react";
import {TextField} from "@mui/material";

/**
 * Muestra un input de texto
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **rows** *int*
 * - **multiline** *bool*
 * - **value** *string*
 * - **onChange** *function*
 * - **required** *bool*
 */
export default function InputText(props) {

    const {label, name, value, onChange, required, rows, multiline, shrink} = props

    const multilineIn = multiline || false
    const rowsIn = rows || 5

    const shrinkIn = shrink ?? true

    return (
        <TextField
            type="text"
            variant="outlined"
            className="form-control"
            size="small"
            fullWidth
            required={required}
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            multiline={multilineIn}
            rows={rowsIn}
            InputLabelProps={{ shrink: shrinkIn }}
        />
    )
}
