import Login from "../pages/Auth/LoginForm"
import ForgottenPassword from "../pages/Auth/ForgottenPassword"
import RecoverPassword from "../pages/Auth/RecoverPassword"
import Logout from "../pages/Auth/Logout";

const baseTitle = "SciFoot - "

export const RouteAuth = {
    LOGIN: {
        path: "/login",
        page: Login,
        title: `${baseTitle}Login`,
        roles: ["ROLE_PUBLIC"]
    },
    RECOVER_PASSWORD: {
        path: "/recover-password",
        page: ForgottenPassword,
        title: `${baseTitle}Recover password`,
        roles: ["ROLE_PUBLIC"]
    },
    RECOVER_PASSWORD_TOKEN: {
        path: "/recover-password/:token",
        page: RecoverPassword,
        title: `${baseTitle}Recover password`,
        roles: ["ROLE_PUBLIC"]
    },
    LOGOUT: {
        path: "/logout",
        page: Logout,
        title: `${baseTitle} Cerrar sesión`,
        roles: ["ROLE_PUBLIC"]
    }
}
