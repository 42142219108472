import * as React from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {RouteUser} from '../../../routes/RouteUser'

export default function Block2() {

    return (
        <div className="block2">
            <div className="block2-flex-container">
                <div className="block2-flex-div">
                    <p>Determina y optimiza el tratamiento térmico para cada proceso de pasteurización, haciendo una valoración y estudio de todas las variables que afectan al proceso, garantizando el cumplimiento de los requisitos de seguridad y calidad de producto.</p>
                </div>
                <div className="block2-flex-div">
                    <p>AppertFlow engloba dos módulos principales: módulo de Validación y módulo de Verificación. En función del tipo de licencia contratada, la aplicación puede incluir únicamente el módulo de validación, o además de este, incluir también el módulo de verificación en diferido o en tiempo real. Todas las licencias incluyen una licencia de uso de LabNotes.</p>
                </div>
            </div>
            <Link href={RouteUser.DEMO_REQUEST.path} underline="none" color="inherit"><Button className="solicitar-button">Solicitar Demo</Button></Link>
            <div className="block2-flex-three-container">
                <div className="block2-flex-div">
                    <p><span>Licencia Proffesional:</span> Incluye el módulo de <span>Validación</span> con herramientas para determinar el tratamiento térmico más adecuado y realizar el estudio de la validación y optimización del proceso de pasteurización del alimento.</p>
                </div>
                <div className="block2-flex-div">
                    <p><span>Licencia Premium:</span> Incluye, además del módulo de validación, el módulo de <span>Verificación</span> sin conexión en tiempo real, donde se dispone de herramientas orientadas a la vigilancia y verificación en diferido de las condiciones previamente validadas.</p>
                </div>
                <div className="block2-flex-div">
                    <p><span>Licencia Premium Real Time:</span> Incluye además de los módulos de <span>validación y verificación</span>, la conexión directa mediante ethernet con el/los PLC del pasteurizador/es, para realizar las tareas de vigilancia y verificación <span>en tiempo real</span> de las condiciones previamente validadas.</p>
                </div>
            </div>
        </div>
    )
}
