import Menu from "./Menu"
import MenuMobile from "./Menu/mobile.js"
import useAuth from "../../../../hooks/useAuth";
import Logo from "../../../../assets/logo.png";
import Link from '@mui/material/Link';
import {RouteUser} from '../../../../routes/RouteUser'

export default function Header() {
    const { auth } = useAuth()

    return (
        <div className="header">
            <div className="logo-container">
                <Link href={RouteUser.ROOT.path}>
                    <img
                        src={Logo}
                        alt="logo"
                        loading="lazy"
                    />
                </Link>
            </div>
            <div className="menu">
                <Menu/>
                <MenuMobile/>
            </div>
        </div>
    );
}
