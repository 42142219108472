import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ContentFaq(props) {
    const {item, headerType} = props;

    return (
        <>
            <Accordion className={headerType.classContent}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className="faq-title">{item.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className="faq-text">
                        {item.description}
                    </Typography>
                    <Typography className="faq-text">
                        <div dangerouslySetInnerHTML={{__html: item.content}}></div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    )
}
