import {useState} from "react"
import Block1 from "../../../components/User/Distributor/block1"
import Block2 from "../../../components/User/Distributor/block2"
import Block3 from "../../../components/User/Distributor/block3"
import {useEffect} from "react";
import ReactGA from "react-ga4";
export default function Distributor() {

    const [scrollToRef, setScrollToRef] = useState({})

    useEffect(() => {
        ReactGA.event({
            category: "Page",
            action: `Distributor`,
        });
    },[])

    return (
        <div className="distributor">
            <Block1 />
            <Block2 scrollToRef={scrollToRef} />
            <Block3 setScrollToRef={setScrollToRef} />
        </div>
    );
}
