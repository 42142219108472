import React, {useEffect, useMemo, useState} from "react"
import {getToken, hasExpiredToken, removeToken, setToken} from "./services/Auth/token"
import jwtDecode from "jwt-decode"
import {useHistory, useLocation} from "react-router-dom";
import AuthContext from "./context/AuthContext"
import {RouteAdmin} from "./routes/RouteAdmin";
import {RouteUser} from "./routes/RouteUser";

export default function Home(props) {

    const [auth, setAuth] = useState(undefined)
    const [reloadUser, setReloadUser] = useState(false)
    const history = useHistory()
    const location = useLocation()

    const logout = () => {
        if (auth) {
            removeToken()
            setAuth(null)
            localStorage.clear()
            history.push(RouteUser.ROOT.path)
        }
    }

    // Mantener los datos del usuario al recargar
    useEffect(() => {
        const token = getToken()
        if (token) {
            if (hasExpiredToken()) {
                logout()
            }

            setAuth({
                primaryUser: token.primaryUser,
                secondaryUser: token.secondaryUser
            })
        } else {
            setAuth(null)
        }

        setReloadUser(false)
    }, [reloadUser, history])  // eslint-disable-line react-hooks/exhaustive-deps

    // Guardar los datos del usuarios, useMemo evita que vaya recargando si no se modifica algun dato
    // Si backImpersonate = true, significa que dejamos de impersonar para volver a ser role admin
    const login = (token) => {
        if (!token?.backImpersonate) {
            token.primaryUser = {
                token: token.primaryUser,
                idUser: jwtDecode(token.primaryUser).id,
                role: jwtDecode(token.primaryUser).roles[0],
                roleName: nameRole(jwtDecode(token.primaryUser).roles[0]),
                name: jwtDecode(token.primaryUser).name,
                surnames: jwtDecode(token.primaryUser).surnames,
            }
        }

        if (token.secondaryUser && token?.initial) {
            token.secondaryUser = {
                token: token.secondaryUser,
                idUser: jwtDecode(token.secondaryUser).id,
                role: jwtDecode(token.secondaryUser).roles[0],
                roleName: nameRole(jwtDecode(token.secondaryUser).roles[0]),
                name: jwtDecode(token.secondaryUser).name,
                surnames: jwtDecode(token.secondaryUser).surnames,
            }
        }

        // primaryUser
        let primaryUser = token.primaryUser

        // secondaryUser (admin)
        let secondaryUser = token.secondaryUser ?? {}

        let newToken = {
            primaryUser,
            secondaryUser
        }

        setAuth(newToken)
        setToken(newToken)

        if (token.backImpersonate) {
            history.push(RouteAdmin.USER_MANAGEMENT.path)
        }
    }

    const nameRole = (role) => {
        let roleName = ""
        switch (role) {
            case "ROLE_ADMIN":
                roleName = "Administrador"
                break;
            case "ROLE_USER":
                roleName = "Usuario"
                break;
            default:
                roleName = "Usuario"
        }
        return roleName
    }

    const authData = useMemo(
        () => ({
            auth: auth,
            login: login,
            logout: logout,
            setReloadUser: setReloadUser
        }), [auth]  // eslint-disable-line react-hooks/exhaustive-deps
    )

    if (auth === undefined) {
        return null
    }

    return (
        <AuthContext.Provider value={authData}>
            {props.children}
        </AuthContext.Provider>
    )
}
