export const generalText = {
    tutoriales: {
        text: `
            Nota:<br/>
            Estos archivos se proporcionan a modo de ejemplo.<br/><br/>

            SciFood Studio no garantiza que los datos contenidos en los ejemplos estén libres de errores ni
            que cumplan con los requisitos del usuario.<br/>
            SciFood Studio no ofrece garantías, expresas o implícitas, incluidas, entre otras, las garantías
            de idoneidad para un propósito particular.<br/>
            SciFood Studio no tendrá ninguna responsabilidad por los daños que surjan o estén relacionados
            con la entrega, el uso, la eficiencia o la idoneidad de estos ejemplos o la aplicación de los
            resultados.`
    }
}
