import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {apiCreate, apiGetOne, apiUpdate} from "../../../../services/Admin/distributor";
import InputText from "../../../../components/Inputs/InputTextAdmin";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {Grid, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import InputSwitch from "../../../../components/Inputs/InputSwitch";
import LoadingBar from "../../../../components/LoadingBar";
import {apiList as apiListType} from "../../../../services/Admin/type";
import InputAutocomplete from "../../../../components/Inputs/InputAutocomplete";

export default function Fields() {
    const {id} = useParams()
    const history = useHistory()
    const {t} = useTranslation()
    const {i18n} = useTranslation()
    const params = new URLSearchParams(window.location.search)

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [optionsType, setOptionsType] = useState([])

    const myRef = useRef(null)

    useEffect(() => {
        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, [])

    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        getSelectorType()
        if (id) {
            getElement()
        } else {
            setState({...state, loading: false})
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getElement = () => {
        setMsgError([])

        apiGetOne({
            id,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            setState({
                ...state,
                loading: false,
                id,
                name: resp.data?.name,
                web: resp.data?.web,
                address: resp.data?.address,
                phone: resp.data?.phone,
                isActive: resp.data?.isActive,
                type: resp.data?.type
            })
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setState({...state, loading: false, id})
        })
    }

    const handleChangeChecked = (event) => {
        state[event.target.name] = event.target.checked
        setState({...state})
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        if (id) {
            void updateElement()
        } else {
            void createElement()
        }
    }

    const updateElement = () => {
        apiUpdate({
            state,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const createElement = () => {
        apiCreate({
            state,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            history.push(`${RouteAdmin.DISTRIBUTOR_EDIT.path}/${resp.data?.id}?msg=${resp.data?.msg}`)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const getSelectorType = () => {
        apiListType({
            lang: i18n.resolvedLanguage,
            limit: 1000,
            offset: state.offset ?? 0,
            field: 'name',
            sort: 'desc',
            isActive: 1,
            isDistributors: true
        }).then(resp => {
            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc
                }
            })
            setOptionsType(temp)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    return (
        <>
            <div ref={myRef}></div>
            <HeaderPage title="Configuración del Distribuidores" urlBack={RouteAdmin.DISTRIBUTOR_MANAGEMENT.path}/>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{display: 'flex', flexDirection: 'column', borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
                        <LoadingBar loading={state.loading} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={8}>
                                <h4 className="p-0 mt-0">{t('user.settings')}</h4>
                            </Grid>

                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={3}>

                                    <Grid item xs={6}>
                                        <InputText
                                            label="Nombre"
                                            name="name"
                                            value={state?.name}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputText
                                            label="Dirección"
                                            name="address"
                                            value={state?.address}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputText
                                            label="Web"
                                            name="web"
                                            value={state?.web}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputText
                                            label="Teléfono"
                                            name="phone"
                                            value={state?.phone}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputAutocomplete
                                            label="Tipo"
                                            name="type"
                                            required
                                            onChange={handleChange}
                                            value={state?.type}
                                            options={optionsType}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <div style={{float: 'right'}}>
                                            <InputSwitch
                                                label="Activo"
                                                name="isActive"
                                                checked={state?.isActive}
                                                onChange={handleChangeChecked}
                                            />
                                        </div>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}>
                    <LoadingButton
                        onClick={handleSubmit}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        fullWidth
                    >
                        {id ? t('Modificar') : t('Crear')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    const model = {
        name: ""
    }

    return {
        id: props?.id || null,
        name: props?.name || "",
        address: props?.address || "",
        web: props?.web || "",
        phone: props?.phone || "",
        isActive: props?.isActive || true,
        type: props?.type || null,
        loading: true
    }
}
