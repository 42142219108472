export const SectionsType = {
    biografia: {
        id: 8,
        title: "Bibliografía:",
        subtitle: "Amplía tu formación",
        text: "SciFood Studio recomienda los siguientes libros para complementar la formación del usuario:",
        class: "bibliografia-container",
        classContent: "bibliografia-content"
    },
    tutoriales: {
        id: 10,
        title: "Tutoriales:",
        subtitle: "Iníciate con el software",
        text: "Son archivos diseñados para ayudar a los nuevos usuarios a dar los primeros pasos con SciFood. Muestran en detalle el proceso a seguir para familiarizarse adecuadamente con el software, construir modelos sencillos, proyectos de ejemplo… y aprovechar al máximo las capacidades del software.",
        class: "tutoriales-container",
        classContent: "tutoriales-content",
    },
    descargas: {
        id: 11,
        title: "Descargas:",
        subtitle: "",
        text: "",
        class: "descargas-container",
        classContent: "descargas-content"
    },
    faq: {
        id: 9,
        title: "FAQ’s:",
        subtitle: "",
        text: "Preguntas frecuentes sobre nuestro sistema de licencias, nuestro software, conceptos técnicos, etc… Para más información, utilice nuestro formulario de contacto.",
        class: "faq-container",
        classContent: "faq-content"
    },
    enlacesDeInteres: {
        id: 12,
        title: "Enlaces de interés",
        subtitle: "",
        text: "",
        class: "links-container",
        classContent: "links-content"
    },
    videos: {
        id: 13,
        title: "Vídeos:",
        subtitle: "",
        text: "Para más vídeos consulta nuestro canal de Youtube.",
        class: "videos-container",
        classContent: "videos-content"
    },
}
