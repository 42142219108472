import {useEffect} from "react";
import ReactGA from "react-ga4";
export default function PrivacyPolicy() {

        useEffect(() => {
                ReactGA.event({
                        category: "Page",
                        action: `PrivacyPolicy`,
                });
        },[])

    return (
        <div className="privacy-policy">
            <h1>Política de privacidad</h1>
            <p><span className="text-bold">"Advertencia importante"</span>: como usuario de este sitio web le invitamos a leer detenidamente el siguiente texto legal con carácter previo a facilitar cualquier dato de carácter personal.</p>
            <p>GÉMINA I MÁS D, S.L. como titular del sitio web SciFoodStudio.net (en adelante “GÉMINA”), y en cumplimiento de las obligaciones establecidas en el artículo 5 y concordantes de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal le informa en relación con su política de protección de datos personales, con el fin de que, de forma expresa, libre, y voluntaria, decida si desea facilitar a GÉMINA los datos personales que, en su caso, les sean solicitados en el sitio web para la prestación de los servicios o la venta de los productos en él ofertados y cualquier otra finalidad prevista en el fichero/s que a tal efecto figura debidamente inscrito en la Agencia Española de Protección de Datos.</p>
            <p className="text-bold">MÉTODOS Y PROCEDIMIENTOS PARA RECABAR DATOS PERSONALES:</p>
            <p>El usuario del sitio web de GÉMINA deberá cumplimentar los formularios web con datos verdaderos, exactos, completos y actualizados, respondiendo de los daños y perjuicios que pudiera ocasionar a causa de la cumplimentación defectuosa de dichos formularios al facilitar información inexacta, incompleta o no actualizada.</p>
            <p>Salvo que se contemple previsión expresa en contrario, se considerará necesario cumplimentar todos los datos requeridos en los formularios del Portal. En el caso de no suministrar todos los datos indicados como necesarios, GÉMINA podrá, dependiendo del caso en particular, no proceder al registro del usuario, solicitar la actualización o subsanación de los datos, o bien denegar el registro en el servicio solicitado por el usuario.</p>
            <p>Los servicios de GÉMINA no están dirigidos a menores de edad, por lo que GÉMINA en ningún momento solicitará datos relativos a menores de edad. </p>
            <p className="text-bold">Redes sociales, blogs y comunicaciones comerciales:</p>
            <p>El usuario podrá suscribirse a los servicios de actualizaciones o información, boletines, perfiles de redes sociales o cualquier otro servicio de comunicación que GÉMINA ponga a su disposición a través de su sitio web. En este sentido, y sin perjuicio de la posibilidad de causar baja en cualquier momento en dichos servicios, el usuario autoriza a GÉMINA al envío de las comunicaciones en relación con las finalidades referidas en la presente Política de privacidad y en particular las finalidades previstas en el fichero “USUARIOS DEL PORTAL” registrado en la Agencia Española de Protección de Datos y accesible de forma gratuita mediante el sitio web de dicha entidad.</p>
            <p className="text-bold">FINALIDADES Y CESIONES:</p>
            <p>Se informa a los usuarios de la incorporación de los datos de carácter personal que estos faciliten a los correspondientes ficheros inscritos en la Agencia Española de Protección de Datos, cuyo titular y responsable es GÉMINA I MÁS D, S.L., con domicilio social en Polígono Industrial los Romerales parc. 3 y 4, C.P. 30520, Jumilla (Murcia), inscrita en el Registro Mercantil de Murcia, Tomo 3334, Libro 0, Folio 21, Hoja MU-98209, inscripción 1, con NIF: B-05505649, correo electrónico: gemina@gemina.es, teléfono 968 716018.</p>
            <p>La finalidad o finalidades del tratamiento/s de dichos datos serán, sin perjuicio de las contempladas en los citados ficheros inscritos, las siguientes:</p>
            <ul>
                <li>Prestar al USUARIO el servicio que éste haya solicitado a GÉMINA, que con carácter general se referirá al registro en el sitio web y/o prestación de los servicios propios de GÉMINA I MÁS D SL consistentes en servicios de ingeniería, proyectos, diseño y ejecución de fábricas y procesos industriales, proyectos llave en mano y asesoramiento técnico. </li>
                <li>Remitir comunicaciones de carácter informativo y/o comercial en relación con los servicios ofertados por GÉMINA o servicios de análoga naturaleza directamente relacionados con los ofertados en el portal.</li>
            </ul>
            <p>Las referidas comunicaciones podrán llevarse a cabo tanto a través de medios de comunicación convencional como a través de medios de comunicación electrónica (correo electrónico), telefónica (SMS o similares), redes sociales o de cualquier otra naturaleza.</p>
            <p>La aceptación de la presente cláusula implica su consentimiento para comunicar los datos facilitados por el usuario a las empresas, entidades colaboradoras y personas que a su vez presten servicios a GÉMINA con el fin de llevar a cabo la contratación solicitada por el usuario o llevar a término las actividades previstas en el resto de finalidades establecidas en el presente documento.</p>
            <p>Con carácter general los datos personales recabados del usuario no serán cedidos a ninguna empresa ni estarán disponibles para terceros, más allá de las comunicaciones imprescindibles a los proveedores de la información que GÉMINA facilita a sus usuario, y de las cesiones previstas en los ficheros inscritos en la Agencia Española de Protección de Datos y referidas en el apartado anterior, siendo utilizados únicamente para los fines establecidos en el momento de la recogida o recepción de los mismos.</p>
            <p>En cualquier caso, si se prevé realizar alguna cesión específica para supuestos concretos se informará de la misma con carácter previo a la hora recabar los datos personales del usuario.</p>
            <p className="text-bold">MEDIDAS DE SEGURIDAD:</p>
            <p>En virtud de la presente Política de privacidad se informa al usuario que GÉMINA se obliga a cumplir con las medidas técnicas y organizativas con el fin de evitar la pérdida, mal uso, alteración acceso no autorizado y demás riesgos posibles de conformidad con la legislación aplicable a la materia, y en particular con lo dispuesto en el Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal.</p>
            <p>Asimismo, le informamos que GÉMINA ha implementado las medidas de seguridad necesarias para garantizar que los datos y las transacciones de pago realizadas por el usuario se realicen siempre en un entorno seguro.</p>
            <p className="text-bold">DEBER DE SECRETO Y CONFIDENCIALIDAD:</p>
            <p>GÉMINA se compromete a cumplir con el deber de secreto profesional respecto a la información facilitada por el USUARIO, así como al deber de guardar dicha información de conformidad con lo establecido en el artículo 10 de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de carácter Personal.</p>
            <p>Asimismo, GÉMINA se compromete a no revelar a terceros la información que tenga carácter de información confidencial.</p>
            <p className="text-bold">EJERCICIO DE DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN (DERECHOS “ARCO”):</p>
            <p>El usuario o la persona que lo represente previa acreditación de dicha representación, podrá ejercitar en cualquier momento el derecho de acceso, rectificación, cancelación y/u oposición de conformidad con lo previsto en la Ley 15/1999, de 13 de diciembre Orgánica de Protección de Datos de carácter Personal y en el Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la Ley Orgánica 15/1999, de 13 de diciembre, de protección de datos de carácter personal.</p>
            <p>A tal efecto deberá remitir una comunicación escrita (siendo válido también el correo electrónico) y en la cual se acredite suficientemente la identidad del usuario remitiendo copia del DNI o documento identificativo similar, al domicilio social o correo electrónico que figura disponible en el “AVISO LEGAL” de la web de GÉMINA.</p>
        </div>
    );
}
