import React from "react";
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import {RouteUser} from '../../../../routes/RouteUser'
import Logo from '../../../../assets/logo.png'
import Image4 from '../../../../assets/Uploads/support/Vector_2.png'
import {Button} from "@mui/material";

export default function ContentDescargas(props) {
    const {item, headerType} = props

    let image = item.photos[0] ?? Logo

    const downloadFile = (documentation) => {
        documentation.forEach(document => {
            window.open(document, '_blank');
        })
    }

    return (
        <>
            <div className={headerType.classContent}>
                <div className="descargas-img">
                    <img src={image}/>
                </div>
                <div className="descargas-text">
                    <h5>{item.name}</h5>
                    <p>{item.description}</p>
                </div>
                <div className="descargas-link">
                    <Button
                        onClick={() => downloadFile(item.documentation)}
                    >
                        <IconButton>
                            <img src={Image4} className="block2-image"/>
                        </IconButton>
                    </Button>
                </div>
            </div>
        </>
    )
}
