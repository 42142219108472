import React, {useEffect, useRef} from 'react';
import Block1 from "../../../components/User/SupportContent/block1"
import Menu from "../../../components/User/SupportContent/Menu"
import Block3 from "../../../components/User/SupportContent/block3"
import Block4 from "../../../components/User/SupportContent/block4"
import {useState} from "react"
import {useParams} from "react-router-dom";
import CheckRole from "../../../components/Auth/CheckRole";
import ReactGA from "react-ga4";
export default function SupportContent() {

    const [idSubCategory, setIdSubCategory] = useState(null)
    const [headerType, setHeaderType] = useState({})
    const {typeSlug} = useParams()
    const myRef = useRef(null)

    useEffect(() => {
        ReactGA.event({
            category: "Page",
            action: `SupportContent`,
        });
    },[])

    useEffect(() => {
        myRef.current.scrollIntoView()
    },[])

    let supportTypeClass
    let permissionRole = ["ROLE_PUBLIC"]

    typeSlug === "descargas" ? supportTypeClass = "support-type-gray" : supportTypeClass = "support-type-normal"

    typeSlug === "descargas" ? permissionRole = ["ROLE_USER"] : ["ROLE_PUBLIC"]
    
    return (
        <div className="support-content">
            <div ref={myRef}></div>

            <CheckRole roles={permissionRole} />
            <Block1 headerType={headerType}/>
            <div className={`support-type ${supportTypeClass}`}>
                <div className="support-dinamic-content">
                    <Menu
                        setIdSubCategory={setIdSubCategory}
                        setHeaderType={setHeaderType}
                        idSubCategory={idSubCategory}
                    />
                    <div className="support-separator"></div>
                    <Block3
                        idSubCategory={idSubCategory}
                        headerType={headerType}
                    />
                </div>
            </div>
            <Block4/>
        </div>
    );
}
