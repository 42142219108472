import {BASE_PATH, BASE_PATH_EXTENSION, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH}${BASE_PATH_EXTENSION}`;
const config = getToken() ? {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}} : {headers: { }};

export function apiSendDemo(data) {
    return axios.post(`${baseUrl}/es/user/request/demo`, data, config)

}
