import React from "react";
import {Button, Grid} from "@mui/material";
import {RouteAdmin} from "../../routes/RouteAdmin";
import {ArrowBackIos} from "@mui/icons-material";
import {Link} from "react-router-dom";

export default function HeaderPage(props) {
    const {title, children, urlBack} = props

    const urlBackIn = urlBack ? urlBack : null
    return (
        <Grid container justifyContent="flex-start" spacing={1}>
            <Grid item xs={12} md={8} lg={8}>
                <div>
                    {urlBackIn && (
                        <Link
                            type="button"
                            to={urlBack}
                            style={{marginTop: 28, float: 'left', color: 'black'}}
                        >
                            <ArrowBackIos/>
                        </Link>
                    )}
                    <h1 className="p-0" style={{float: 'left'}}>{title}</h1>
                </div>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                {children}
            </Grid>
        </Grid>
    )
}
