import * as React from 'react';
import Img1 from '../../../assets/Uploads/LabNotes/Frame.png'
import Img2 from '../../../assets/Uploads/LabNotes/Frame-1.png'
import Img3 from '../../../assets/Uploads/LabNotes/Frame-2.png'
import Img4 from '../../../assets/Uploads/LabNotes/Frame-3.png'
import Img5 from '../../../assets/Uploads/LabNotes/Frame-4.png'
import Img6 from '../../../assets/Uploads/LabNotes/Frame-5.png'

export default function Block3() {

    return (
        <div className="block3">
            <div className="block3-flex-container">
                <div className="block3-flex-divs">
                    <img src={Img1} className="block3-frame-1" />
                    <p>Ayuda a la toma de mejores decisiones de fabricación.</p>
                </div>
                <div className="block3-flex-divs">
                    <img src={Img2} className="block3-frame-2" />
                    <p>Seguimiento y control evolutivo de los parámetros de calidad.</p>
                </div>
                <div className="block3-flex-divs">
                    <img src={Img3} className="block3-frame-3" />
                    <p>Se integra en tiempo real con otras aplicaciones de SciFood Studio.</p>
                </div>
            </div>
            <div className="block3-flex-container">
                <div className="block3-flex-divs">
                    <img src={Img4} className="block3-frame-4" />
                    <p>Realiza informes de resultados analíticos.</p>
                </div>
                <div className="block3-flex-divs">
                    <img src={Img5} className="block3-frame-5" />
                    <p>Dato único: anotación y registro de información fisicoquímica, microbiológica y sensorial.</p>
                </div>
                <div className="block3-flex-divs">
                    <img src={Img6} className="block3-frame-6" />
                    <p>Registra parámetros que afectan directamente a los procesos de fabricación.</p>
                </div>
            </div>
        </div>
    )
}
