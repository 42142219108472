import React from "react";
import {FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, TextField} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

/**
 * Muestra un input de contraseña
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **value** *string*
 * - **onChange** *function*
 * - **required** *bool*
 */
export default function InputPassword(props) {

    const {label, name, value, onChange, required} = props

    const [showPassword, setShowPassword] = React.useState(false)

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    return (
        <Grid container>
            <Grid item md={10}>
                <TextField
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    className="form-control"
                    size="small"
                    required={required}
                    fullWidth
                    inputProps={{
                        autoComplete: "autocomplete=\"ÑÖcompletes\"",
                        form: {
                            autoComplete: "off",
                        },
                    }}
                    label={label}
                    name={name}
                    value={value}
                    onChange={onChange}
                />
            </Grid>
            <Grid item md={2}>
                <IconButton
                    style={{display: "block", margin: "auto"}}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </Grid>
        </Grid>
    )
}
