import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {useEffect} from "react";
import ReactGA from "react-ga4";

export default function CookiesPolicy() {

    useEffect(() => {
        ReactGA.event({
            category: "Page",
            action: `CookiesPolicy`,
        });
    },[])

    return (
        <div className="cookies-policy">
            <h1>Política de Cookies</h1>
            <p>GÉMINA I MÁS D, S.L., titular del portal www.scifoodstudio.net (en adelante, de forma indistinta "GÉMINA") ha elaborado el presente documento con el fin de informarte como usuario del portal, de qué es una cookie, cuál es su finalidad, cuáles con las que empleamos en este portal web y los usos que damos a las mismas, así como los procedimientos para que puedas gestionarlas según consideres oportuno.</p>
            <p>De conformidad con lo dispuesto en el artículo 22.2 de la Ley 34/2002, de 11 de julio, de Servicios de Sociedad de la Información y Comercio Electrónico:</p>
            <p className="text-bold">Qué son las cookies y para qué sirven</p>
            <p>Las cookies son un elemento importante a la hora de facilitar la navegación del usuario y la usabilidad del portal web, haciendo más ágil su experiencia en nuestro sitio y permitiéndole acceder a todas las funcionalidades del mismo.</p>
            <p>Se trata de pequeños archivos de texto, que son almacenados en el disco duro de su terminal o de la memoria de dispositivo análogo del usuario, pero no pueden leer los datos contenidos en él, ni acceder a los archivos de cookies de otros proveedores.</p>
            <p>En el siguiente enlace puede consultar más información sobre <Link href="https://es.wikipedia.org/wiki/Cookie_(informática)">cookies ("Wikipedia")</Link>.</p>
            <p className="text-bold">Qué cookies emplea el portal SciFoodStudio</p>
            <p>En particular, en relación con las cookies se pueden realizar distintas clasificaciones de las mismas en función de distintos criterios. De esta forma, se puede distinguir entre:</p>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                Criterio
                            </TableCell>
                            <TableCell align="center">
                                Tipo de cookie
                            </TableCell>
                            <TableCell align="center">
                                Finalidad
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell rowSpan={2}>
                                Según quien es el titular del equipo desde el que se envían las cookies y/o quien trata los datos obtenidos.
                            </TableCell>
                            <TableCell>
                                Propias
                            </TableCell>
                            <TableCell>
                                De terceros
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Enviadas al usuario o gestionadas por el editor del portal web.
                            </TableCell>
                            <TableCell>
                                Enviadas al usuario o gestionadas por un tercero distinto del titular del Portal.
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell rowSpan={2}>
                                Según el periodo de tiempo que permanecen instaladas en el terminal del usuario o permanecen activas.
                            </TableCell>
                            <TableCell>
                                De sesión
                            </TableCell>
                            <TableCell>
                                Diseñadas para recabar datos únicamente mientras el usuario está conectado al Portal.
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Persistentes
                            </TableCell>
                            <TableCell>
                                Diseñadas para recabar datos aun cuando el usuario ya no esté conectado con el Portal teniendo una duración establecida por quien gestiona o envía la cookie.
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell rowSpan={4}>
                                Según la finalidad para la que se traten los datos obtenidos de las mismas.
                            </TableCell>
                            <TableCell>
                                Técnicas
                            </TableCell>
                            <TableCell>
                                Facilitan la navegación del usuario por el Portal o le prestan un servicio por él solicitado.
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                De personalización
                            </TableCell>
                            <TableCell>
                                Permiten al usuario acceder al Portal con base en una configuración predeterminada acorde a su necesidad (Ej. Idioma, navegador, resolución pantalla, etc.).
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Analíticas
                            </TableCell>
                            <TableCell>
                                Permiten conocer el comportamiento del usuario en el Portal y la elaboración de perfiles de navegación. Generalmente asociadas a uso estadístico.
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Publicitarias y Comportamentales
                            </TableCell>
                            <TableCell>
                                Permiten presentar publicidad específica al usuario en función de sus preferencias y el estudio de sus hábitos de navegación. (Ej. Cookie para hacer remarketing).
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <p>En la siguiente tabla se relaciona las cookies utilizadas por GÉMINA así otra información de interés que puede ser de utilidad para el usuario:</p>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                Nombre
                            </TableCell>
                            <TableCell align="center">
                                Tipo cookie
                            </TableCell>
                            <TableCell align="center">
                                Finalidad
                            </TableCell>
                            <TableCell align="center">
                                Información
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className="text-bold">
                                Google Analytics
                            </TableCell>
                            <TableCell>
                                De terceros. Analítica.
                            </TableCell>
                            <TableCell>
                                Recopilar información anónima de uso del portal por los visitantes para ofrecer estadísticas de uso.
                            </TableCell>
                            <TableCell>
                                Para más información pinche <Link href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es-419&utm_source=devtools">aquí</Link>.
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <p className="text-bold">Cómo gestiona las cookies GÉMINA</p>
            <p>El usuario puede evitar la generación o instalación de cookies, así como bloquear o eliminar las mismas mediante la selección de la opción correspondiente en el menú de su navegador.</p>
            <p>Sin embargo, en este caso le advertimos que es posible que el usuario no pueda acceder a los servicios ofertados en la web o lo haga de forma parcial o no satisfactoria. A continuación se facilita información sobre cómo configurar de forma personalizada los aspectos relativos a cookies en los navegadores y dispositivos de uso más común:</p>
            <ul>
                <li>Mozilla Firefox</li>
                <li>Google Chrome</li>
                <li>Chrome para Android</li>
                <li>Internet Explorer</li>
                <li>Safari</li>
                <li>Safari para IOS (iPhone y iPad)</li>
            </ul>
            <p className="text-bold">Información y Consentimiento</p>
            <p>El usuario, al visitar y continuar con la navegación en el presente sitio web manifiesta que ha sido informado suficientemente así como su consentimiento en relación con el uso de cookies por parte de GÉMINA en los términos enunciados en este documento.</p>
            <p className="text-bold">Modificación</p>
            <p>GÉMINA se reserva el derecho de modificar su política de cookies por adaptación a la legislación vigente, u otros motivos. Es por esto que se recomienda al usuario del portal la revisión periódica de esta página.</p>
        </div>
    );
}
