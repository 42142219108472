import * as React from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Image5 from '../../../assets/Uploads/home/shutterstock_481902994.webp'
import Image6 from '../../../assets/Uploads/home/shutterstock_1696299955.webp'
import Image7 from '../../../assets/Uploads/home/shutterstock_1681965871-2.webp'
import Link from '@mui/material/Link';
import {RouteUser} from '../../../routes/RouteUser'
import CatalogoPDF from "../../../assets/Files/catalogo_scifood_v2.pdf";

export default function Block3() {


    return (
        <div className="block3">
            <div className="block3-content">
                <div className="block3-images-container">
                    <div className="block3-first-images-block">
                        <div>
                            <div className="block3-images-filter"></div>
                            <img src={Image5} className="block3-image" />
                        </div>
                        <div className="block3-first-text-block">
                            <h3>Más excelencia, más tranquilidad</h3>
                            <p>Cumple con los estándares de calidad y supera los requisitos mínimos, demostrando un firme compromiso por la calidad y la seguridad alimentaria.</p>
                        </div>
                    </div>
                    <div className="block3-images-second-block-container">
                        <div className="block3-images-block">
                            <div>
                                <div className="block3-images-filter"></div>
                                <img src={Image6} className="block3-image" />
                            </div>
                            <div className="block3-text-block">
                                <h3>La calidad como tu valor diferencial</h3>
                                <p>Refuerza tu propuesta de valor ante los clientes superando con facilidad los criterios mínimos establecidos.</p>
                            </div>
                        </div>
                        <div className="block3-images-block">
                            <div>
                                <div className="block3-images-filter"></div>
                                <img src={Image7} className="block3-image" />
                            </div>
                            <div className="block3-text-block">
                                <h3>Lidera la transformación del sector</h3>
                                <p>Gracias a la mejor tecnología, podrás adelantarte en tu sector y poner el foco en la calidad y la seguridad alimentaria.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block3-text-bottom">
                    <h4>Scifood Studio en un vistazo:</h4>
                    <Link href={CatalogoPDF} download="SciFood Studio Catalogo.pdf" underline="none" color="inherit"><Button className="solicitar-button">Descarga el catálogo</Button></Link>
                </div>
                <Divider variant="inset" component="div" className="divider" />
            </div>
        </div>
    )
}
