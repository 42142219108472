import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputSelect from "../../Inputs/InputSelect";
import InputText from "../../Inputs/InputText";
import MsgError from "../../MsgError";
import MsgSuccess from "../../MsgSuccess";
import Link from '@mui/material/Link';
import FormLabel from '@mui/material/FormLabel';
import {apiSendDemo} from "../../../services/User/requestMail"
import {RouteUser} from '../../../routes/RouteUser'

export default function Block1() {
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues())
    const myRef = useRef(null)

    const optionsJobFunction = [{id: "Ejecutivo", name: "Ejecutivo"}, {id: "Administrador", name: "Administrador"}, {id: "Ingeniero", name: "Ingeniero"}, {id: "Técnico desarrollo alimentos", name: "Técnico desarrollo alimentos"}, {id: "Producción alimentos", name: "Producción alimentos"}, {id: "Control de calidad", name: "Control de calidad"}, {id: "I+D", name: "I+D"}, {id: "Investigador", name: "Investigador"}, {id: "Estudiante", name: "Estudiante"}, {id: "Otro", name: "Otro"}]
    const optionsInterestLevel = [{id: "Interés general", name: "Interés general"}, {id: "Evaluar producto", name: "Evaluar producto"}, , {id: "Listo para adquirir", name: "Listo para adquirir"}]

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleChangeCheckbox = (event) => {
        state[event.target.name] = event.target.checked
        setState({...state})
    }

    const handleSubmit = (event) => {
        event.preventDefault()
    
        setMsgError([])
        setMsgSuccess([])
        let error = validationSchema(state)
    
        if (error.length !== 0) {
            setMsgError(error)
            myRef.current.scrollIntoView()
            return
        }

        apiSendDemo(state).then(resp => {
            setMsgSuccess(resp.data?.msg)
            myRef.current.scrollIntoView()
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            myRef.current.scrollIntoView()
        })
    }

    return (
        <div className="block1">
            <div ref={myRef}></div>
            <div className="form-title">
                <h1>Un paso más hacia la excelencia</h1>
            </div>
            <div className="form-subtitle">
                <p>Solicita una demo sin compromiso y nos pondremos en contacto contigo para presentarte la herramienta y ayudarte a sacarle el máximo partido.</p>
            </div>
            <div className="form-container">
                <FormControl>
                    <MsgError msg={msgError}/>
                    <MsgSuccess msg={msgSuccess}/>
                    
                    <Grid container>
                        <div className="form-step-title">Datos de contacto</div>

                        <Grid item className="input-column">
                            <InputText
                                label="Nombre"
                                required
                                name="name"
                                value={state?.name}
                                onChange={handleChange}
                                id="name"
                                shrink={false}
                            />
                            <InputText
                                label="Apellidos"
                                name="surnames"
                                value={state?.surnames}
                                onChange={handleChange}
                                id="surnames"
                                shrink={false}
                            />
                            <InputText
                                label="Email"
                                required
                                name="email"
                                value={state?.email}
                                onChange={handleChange}
                                shrink={false}
                            />
                            <InputText
                                label="Teléfono"
                                required
                                name="phone"
                                value={state?.phone}
                                onChange={handleChange}
                                shrink={false}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <div className="form-step-title">Datos de empresa</div>

                        <Grid item className="input-column">
                            <InputText
                                label="Empresa"
                                required
                                name="company"
                                value={state?.company}
                                onChange={handleChange}
                                shrink={false}
                            />
                            <InputSelect
                                name="jobFunction"
                                required={true}
                                options={optionsJobFunction}
                                onChange={handleChange}
                                value={state?.jobFunction}
                                label="Función laboral *"
                                labelOnOption={true}
                                shrink={false}
                            />
                            <InputText
                                label="Dirección"
                                required
                                name="address"
                                value={state?.address}
                                onChange={handleChange}
                                shrink={false}
                            />
                            <InputText
                                label="País"
                                name="country"
                                value={state?.country}
                                onChange={handleChange}
                                shrink={false}
                            />
                            <InputText
                                label="Ciudad"
                                name="city"
                                value={state?.city}
                                onChange={handleChange}
                                shrink={false}
                            />
                            <InputText
                                label="Código postal"
                                name="zipCode"
                                value={state?.zipCode}
                                onChange={handleChange}
                                shrink={false}
                            />
                            <InputSelect
                                name="interestLevel"
                                options={optionsInterestLevel}
                                onChange={handleChange}
                                value={state?.interestLevel}
                                label="Nivel de interés"
                                labelOnOption={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="checkbox-container">
                        <Grid item xs={12} className="formcontrollabel-container">
                            <FormControlLabel control={<Checkbox />} required onChange={handleChangeCheckbox} name="privacyPolicy" className="first-checkbox" label={<div><span>He leído y acepto la </span><Link href={RouteUser.PRIVACY_POLICY.path} target="_blank" color="inherit">Política de Privacidad.</Link><span> *</span></div>} />
                        </Grid>
                        <Grid item xs={12} className="second-checkbox-container">
                            <Checkbox name="newsletter" onChange={handleChangeCheckbox} className="second-checkbox" />
                            <FormLabel>Acepto recibir comunicaciones de ventas y marketing con propósitos comerciales de acuerdo a nuestra política de privacidad.</FormLabel>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="button-container">
                        <Grid item xs={12} className="button-grid">
                            <Button onClick={handleSubmit} className="submit-button">Enviar</Button>
                        </Grid>
                    </Grid>
                </FormControl>
            </div>
            <div className="campo-requerido">
                <p>(*) Campo requerido.</p>
            </div>
        </div>
    )
}

function initialValues(props) {
    return {
        name: "",
        surnames: "",
        email: "",
        phone: "",
        company: "",
        jobFunction: "",
        address: "",
        country: "",
        city: "",
        zipCode: "",
        interestLevel: "",
        privacyPolicy: false,
        newsletter: false,
        subject: "Solicitar demo"
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.name) { error.push("El nombre es obligatorio") }
    if (!schema.email) { error.push("El email es obligatorio") }
    if (!schema.phone) { error.push("El teléfono es obligatorio") }
    if (!schema.company) { error.push("La empresa es obligatoria") }
    if (!schema.jobFunction) { error.push("La función laboral es obligatoria") }
    if (!schema.privacyPolicy) { error.push("La política de privacidad es obligatoria") }

    return error
}
