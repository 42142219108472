import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {apiCreate, apiGetOne, apiUpdate} from "../../../../services/Admin/category";
import {apiList as apiListType} from "../../../../services/Admin/type";
import InputText from "../../../../components/Inputs/InputTextAdmin";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {Grid, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import InputSelect from "../../../../components/Inputs/InputSelect";
import {lang} from "../../../../utils/lang";
import clone from "../../../../utils/clone";
import InputSwitch from "../../../../components/Inputs/InputSwitch";
import LoadingBar from "../../../../components/LoadingBar";

export default function Fields() {
    const {id} = useParams()
    const history = useHistory()
    const {t} = useTranslation()
    const {i18n} = useTranslation()
    const params = new URLSearchParams(window.location.search)

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [optionsType, setOptionsType] = useState([])

    const myRef = useRef(null)

    useEffect(() => {
        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, [])

    // Si no existe el idioma iniciamos el modelo. Es decir, montamos estructura
    useEffect(() => {
        if (!state.trans[state.langSelect]) {
            setState({...state, trans: {...state.trans, [state.langSelect]: clone(state.model)}})
        }
    }, [state.langSelect])

    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        if (id) {
            void getType()
        } else {
            if (!state.trans[state.langSelect]) {
                setState({...state, loading: false, trans: {...state.trans, [state.langSelect]: clone(state.model)}})
            }
        }
        getSelectorType()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getType = () => {
        setMsgError([])

        apiGetOne({
            id,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            setState({...state, loading: false, id, items: resp.data?.items, trans: resp.data?.trans})
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setState({...state, loading: false, id})
        })
    }

    const handleChangeTrans = (event) => {
        state['trans'][state.langSelect][event.target.name] = event.target.value
        setState({...state})
    }

    const handleChangeChecked = (event) => {
        state["items"][event.target.name] = event.target.checked
        setState({...state})
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleChangeItem = (event) => {
        state["items"][event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        if (id) {
            void updateCategory()
        } else {
            void createCategory()
        }
    }

    const updateCategory = () => {
        apiUpdate({
            state,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const createCategory = () => {
        apiCreate({
            state,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            history.push(`${RouteAdmin.CATEGORY_EDIT.path}/${resp.data?.id}?msg=${resp.data?.msg}`)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const getSelectorType = () => {
        apiListType({
            lang: i18n.resolvedLanguage,
            limit: 1000,
            offset: state.offset ?? 0,
            field: 'name',
            sort: 'desc',
            isActive: true,
            isPost: true,
        }).then(resp => {
            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc
                }
            })
            setOptionsType(temp)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    return (
        <>
            <div ref={myRef}></div>
            <HeaderPage title="Configuración del Categoria" urlBack={RouteAdmin.CATEGORY_MANAGEMENT.path}/>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{display: 'flex', flexDirection: 'column', borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
                        <LoadingBar loading={state.loading} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={8}>
                                <h4 className="p-0 mt-0">{t('user.settings')}</h4>
                            </Grid>

                            <Grid item xs={4}>
                                <InputSelect
                                    label={"Idioma"}
                                    name="langSelect"
                                    required
                                    onChange={handleChange}
                                    value={state?.langSelect}
                                    options={lang}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={3}>

                                    <Grid item xs={6}>
                                        <InputText
                                            label={t('user.first_name')}
                                            name="name"
                                            value={state?.trans?.[state.langSelect]?.name}
                                            onChange={handleChangeTrans}

                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputSelect
                                            label="Tipo"
                                            name="type"
                                            state={state}
                                            onChange={handleChangeItem}
                                            value={state?.items?.type}
                                            options={optionsType}
                                        />
                                    </Grid>

                                    <Grid item xs={6}></Grid>
                                    <Grid item xs={6}>
                                        <div style={{float: 'right'}}>
                                            <InputSwitch
                                                label="Activo"
                                                name="isActive"
                                                checked={state?.items?.isActive}
                                                onChange={handleChangeChecked}
                                            />
                                        </div>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}>
                    <LoadingButton
                        onClick={handleSubmit}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        fullWidth
                    >
                        {id ? t('Modificar') : t('Crear')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    const model = {
        name: ""
    }

    return {
        id: props?.id || null,
        langSelect: props?.langSelect || "es",
        trans: props?.trans || {},
        items: props?.items || {
            isActive: true,
            type: null
        },
        model: model,
        loading: true
    }
}
