import React, {useEffect, useState} from "react"

export default function DownloadModal(props) {

    const {modalIsOpen, setModalIsOpen} = props

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className="download-modal" style={modalIsOpen ? {display: 'block'} : {display: 'none'}}>
            <div className="download-modal-text-container">
                <p>Una vez instalado SciFood Studio, regístrate completando el formulario con tus datos y utiliza el código promocional <span>PROMO24</span>, previo paso a la activación gratuita de tu licencia Proffesional.</p>
                <button onClick={closeModal}>Cerrar</button>
            </div>
        </div>
    );
}
