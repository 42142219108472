import React, {useEffect, useState} from "react";
import {apiList} from "../../../../services/Admin/category";
import moment from "moment";
import {Button, Grid, IconButton, Paper} from "@mui/material";
import MsgError from "../../../../components/MsgError";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import Table from "../../../../components/Table";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import InputText from "../../../../components/Inputs/InputTextAdmin";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import {apiList as apiListType} from "../../../../services/Admin/type";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InputAutocomplete from "../../../../components/Inputs/InputAutocomplete";

export default function Management() {
    const {i18n} = useTranslation()

    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [optionsType, setOptionsType] = useState([])


    useEffect(() => {
        getSelectorType()
    }, [])

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
            getSelectorType()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos

        let params = {
            lang: i18n.resolvedLanguage,
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: state.sort.field,
            sort: state.sort.sort
        }

        if (state.id) { params.id = state.id }
        if (state.name) { params.name = state.name }
        if (state.type) { params.type = state.type }

        apiList(params).then(resp => {
            setRowCount(resp.data?.count)

            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc,
                    creationDate: moment.unix(doc?.creationDate).format("D/M/Y"),
                    isActive: doc.isActive ? "Si" : "No"
                }
            })

            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    const getSelectorType = () => {
        apiListType({
            lang: i18n.resolvedLanguage,
            limit: 1000,
            offset: state.offset ?? 0,
            field: 'name',
            sort: 'desc',
            isPost: true,
        }).then(resp => {
            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc
                }
            })
            setOptionsType(temp)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    return (
        <>
            <HeaderPage title="Categoria">
                <Button
                    variant="contained"
                    color="primary"
                    style={{float: "right", margin: "20px 0px"}}
                    href={RouteAdmin.CATEGORY_CREATE.path}
                >
                    + Añadir
                </Button>
            </HeaderPage>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MsgError msg={msgError}/>
                </Grid>

                <Grid item xs={12}>

                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>

                        <Filters
                            state={state}
                            setState={setState}
                            optionsType={optionsType}
                        />

                        <Table
                            columns={tableColumns()}
                            rows={rows}
                            rowCount={rowCount}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        id: "",
        name: "",
        type: "",
    }
}

function tableColumns(handleChangeSelection) {
    return [
        {
            field: "id",
            headerName: "ID",
            flex: 1
        },
        {
            field: "creationDate",
            headerName: "FECHA DE CREACIÓN",
            flex: 1
        },
        {
            field: "name",
            headerName: "NOMBRE",
            flex: 5
        },
        {
            field: "typeName",
            headerName: "TIPO",
            flex: 2
        },
        {
            field: "isActive",
            headerName: "ACTIVO",
            flex: 1
        },
        {
            field: "actions",
            headerName: "ACCIONES",
            renderCell: (params) => {
                return (
                    <Link to={`${RouteAdmin.CATEGORY_EDIT.path}/${params.id}`}>
                        <IconButton size="small">
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                    </Link>
                )
            },
            flex: 1
        },
    ]
}

function Filters(props) {

    const {state, setState, optionsType} = props

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    return (
        <Grid container spacing={1} style={{marginBottom: '20px'}}>
            <Grid item xs={3}>
                <InputText
                    label="ID"
                    name="id"
                    value={state.id}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={4}>
                <InputText
                    label="Nombre"
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={4}>
                <InputAutocomplete
                    label="Tipo"
                    name="type"
                    required
                    onChange={handleChange}
                    value={state?.type}
                    options={optionsType}
                />
            </Grid>

            <Grid item xs={1}>
                <Button variant="text"  onClick={() => setState(initialValues())} style={{float: 'right', height: 35}}>
                    <HighlightOffIcon />
                </Button>
            </Grid>

        </Grid>
    )
}
