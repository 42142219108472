import * as React from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {RouteUser} from '../../../routes/RouteUser';
import Img1 from '../../../assets/Uploads/AppertFlow/v01.png';
import Img2 from '../../../assets/Uploads/AppertFlow/v02.png';
import Img3 from '../../../assets/Uploads/AppertFlow/o01_1.png';
import Img4 from '../../../assets/Uploads/AppertFlow/03_1.png';
import Img5 from '../../../assets/Uploads/AppertFlow/03_2.png';
import Img6 from '../../../assets/Uploads/AppertFlow/03_3.png';
import Img7 from '../../../assets/Uploads/AppertFlow/03_4.png';
import VideoBlock from './VideoBlock';
import Video1 from '../../../assets/Uploads/AppertFlow/videos/1.VALIDACION_WEB_SFS.mp4';
import Video2 from '../../../assets/Uploads/AppertFlow/videos/2.OPTIMIZACION_WEB_SFS.mp4';
import Video3 from '../../../assets/Uploads/AppertFlow/videos/1.VIGILANCIA_WEB_SFS.mp4';
import Video4 from '../../../assets/Uploads/AppertFlow/videos/2.VERIFICACION_ESTERILIZACION_SFS.mp4';
import Video5 from '../../../assets/Uploads/AppertFlow/videos/3.VERIFICACION_CAPSULAS_WEB_SFS.mp4';
import Video6 from '../../../assets/Uploads/AppertFlow/videos/4.TRAZABILIDAD_WEB_SFS.mp4';
import Video7 from '../../../assets/Uploads/AppertFlow/videos/5.INFORMES_SFS.mp4';

export default function Block3() {

    return (
        <div className="block3">
            <div className="block3-text-bottom block3-text-bottom-padding-60">
                <h4>Área de validación: Herramientas principales</h4>
            </div>
            <div className="block3-flex-container">
                <div className="block3-flex-divs block3-flex-img">
                    <VideoBlock video={Video1} />
                </div>
                <div className="block3-flex-divs block3-flex-text">
                    <h5>Valida las condiciones de temperatura y caudal a emplear en la pasteurización y/o esterilización del alimento</h5>
                    <p>Mediante un proceso de inferencia gráfico, intuitivo y dinámico, se determinan las condiciones temperatura-tiempo de mantenimiento, que hacen que se obtenga un valor del índice de letalidad acumulada que permita alcanzar el umbral de carga microbiana garante de la inocuidad del producto procesado.</p>
                </div>
            </div>
            <div className="block3-flex-container responsive-reverse">
                <div className="block3-flex-divs block3-flex-text">
                    <h5>Optimiza los parámetros del proceso mediante la superposición de rectas TDT</h5>
                    <p>Mediante un entorno gráfico y dinámico, se optimizan los parámetros del proceso a través de la superposición de rectas de tratamientos de destrucción térmica equivalentes, que permite evaluar las condiciones validadas frente a otros objetivos de inocuidad y/o calidad.</p>
                </div>
                <div className="block3-flex-divs block3-flex-img">
                    <VideoBlock video={Video2} />
                </div>
            </div>
            <div className="block3-text-bottom block3-text-bottom-padding-120">
                <Link href={RouteUser.DEMO_REQUEST.path} underline="none" color="inherit"><Button className="solicitar-button button">Solicitar Demo</Button></Link>
                <h4>Área de verificación: Herramientas principales</h4>
            </div>
            <div className="block3-flex-container">
                <div className="block3-flex-divs block3-flex-img">
                    <VideoBlock video={Video3} />
                </div>
                <div className="block3-flex-divs block3-flex-text">
                    <h5>Vigila temperaturas y caudal durante todo el proceso</h5>
                    <p>AppertFlow monitoriza gráficamente los datos de las variables del proceso en tiempo real (temperatura y caudal). Los valores pueden ser corregidos, si el usuario lo desea, con los valores de error e incertidumbre de los resultados de la calibración de los sensores de la máquina.</p>
                </div>
            </div>
            <div className="block3-flex-container responsive-reverse">
                <div className="block3-flex-divs block3-flex-text">
                    <h5>Verifica la etapa de esterilización del equipo</h5>
                    <p>AppertFlow calcula el valor del Índice de letalidad acumulada F0 alcanzado, en tiempo real, durante la esterilización del equipo previa a la producción, de modo que permite optimizar esta etapa del proceso tanto en términos de inocuidad, como en términos de consumo energético.</p>
                </div>
                <div className="block3-flex-divs block3-flex-img">
                    <VideoBlock video={Video4} />
                </div>
            </div>
            <div className="block3-flex-container">
                <div className="block3-flex-divs block3-flex-img">
                    <VideoBlock video={Video5} />
                </div>
                <div className="block3-flex-divs block3-flex-text">
                    <h5>Verifica la producción mediante cápsulas de auditoría</h5>
                    <p>AppertFlow calcula y representa en tiempo real para cada registro adquirido el valor del Índice de letalidad acumulada suministrado frente al Agente diana y lo verifica por comparación con el valor mínimo inferido durante el proceso de validación, que se necesita para cumplir con el objetivo de inocuidad alimentaria, mostrando mensajes de disconformidad si los hubiera.</p>
                </div>
            </div>
            <div className="block3-flex-container responsive-reverse">
                <div className="block3-flex-divs block3-flex-text">
                    <h5>Obtén trazabilidad con toda la información analítica disponible en cada momento</h5>
                    <p>AppertFlow genera la correlación de todos los registros de Temperatura-Tiempo de tratamiento con los resultados analíticos del lote de alimento que se procesa y del alimento saliente del equipo pasteurizador que son introducidos en LabNotes, generando de este modo trazabilidad entre el proceso de tratamiento térmico y los resultados analíticos de laboratorio.</p>
                </div>
                <div className="block3-flex-divs block3-flex-img">
                    <VideoBlock video={Video6} />
                </div>
            </div>
            <div className="block3-flex-container">
                <div className="block3-flex-divs block3-flex-img">
                    <VideoBlock video={Video7} />
                </div>
                <div className="block3-flex-divs block3-flex-text">
                    <h5>Obtén informes de auditoría personalizados en un solo click</h5>
                    <p>AppertFlow permite crear informes personalizados para cada lote de producto procesado, donde se pueden incluir tanto los resultados e información utilizada durante el proceso de validación del tratamiento térmico, como los resultados obtenidos durante el proceso de vigilancia y verificación de la producción.</p>
                </div>
            </div>
        </div>
    )
}
