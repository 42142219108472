import React from "react";
import ReactPlayer from "react-player";

export default function ContentVideos(props) {
    const {item, headerType} = props;

    return (
        <>
            <div className={headerType.classContent}>
                <div className="videos-title">
                    <h5>{item.name}</h5>
                    <p>{item.description}</p>
                </div>
                <div className="videos-video">
                    {item.videos.map((video, index) => (
                        <div key={index} className="videos-video-item">
                            <div className="item-video">
                                <ReactPlayer
                                    url={video.url}
                                    className='react-player'
                                    width='100%'
                                    height='100%'
                                    controls={true}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
