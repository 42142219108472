import * as React from 'react';
import Button from '@mui/material/Button';
import Image5 from '../../../assets/Uploads/training/shutterstock_1751484320_formacion_3.webp'

export default function Block3(props) {

    const {scrollToRef} = props

    const executeScroll = () => scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  

    return (
        <div className="block3">
            <div className="block3-content">
                <div className="block3-text-bottom">
                    <h4>Capacitar a los empleados es aumentar sus habilidades y hacerlos más eficientes con SciFood. </h4>
                    <Button className="solicitar-button" onClick={executeScroll}>Inscríbete</Button>
                </div>
                <div className="block3-images-container">
                    <div className="block3-first-images-block">
                        <div className='block3-images-filter'></div>
                        <img src={Image5} className="block3-image" />
                        <div className="block3-first-text-block">
                            <h3>Aprende cuando quieras, donde quieras</h3>
                            <p>Con la formación “a la carta”, podemos organizar cada uno de nuestros cursos de formación para varios de tus empleados, en la fecha que más te convenga.<br/><br/>El programa del curso también se puede adaptar de acuerdo a las necesidades específicas.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
