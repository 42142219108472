import React, {useState} from "react"
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Logo from "../../../../assets/logo.png";
import Link from '@mui/material/Link';
import {RouteUser} from '../../../../routes/RouteUser'
import SciFoodStudioExe from "../../../../assets/Files/SciFood_Studio_2.exe";
import DownloadModal from "../DownloadModal";

export default function Footer() {

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleClick = () => {
        setModalIsOpen(true)
    };

    return (
        <div className="footer">
            <DownloadModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
            <div className="footer-content">
                <div className="footer-logo-container">
                    <Link href={RouteUser.ROOT.path}><img src={Logo} /></Link>
                </div>
                <div className="footer-lists-container">
                    <div>
                        <List>
                            <ListItemText primary="Obtén Scifood" className="first-list-item" />
                            <ListItemButton component="a" href={RouteUser.DEMO_REQUEST.path}>
                                <Link href={SciFoodStudioExe} download="SciFoodStudio.exe" underline="hover" onClick={handleClick} className="footer-list-item" color="inherit">{'Descargar SciFood Studio'}</Link>
                            </ListItemButton>
                            <ListItemButton component="a" href="#" style={{display: "none"}}>
                                <ListItemText primary="Clientes" className="footer-list-item" />
                            </ListItemButton>
                            <ListItemButton component="a" href={RouteUser.SUPPORT.path}>
                                <ListItemText primary="Soporte" className="footer-list-item" />
                            </ListItemButton>
                        </List>
                    </div>
                    <div>
                        <List>
                            <ListItemText primary="Explorar" className="first-list-item" />
                            <ListItemButton component="a" href={RouteUser.TRAINING.path}>
                                <ListItemText primary="Formación" className="footer-list-item" />
                            </ListItemButton>
                            <ListItemButton component="a" href={RouteUser.DISTRIBUTOR.path}>
                                <ListItemText primary="Distribuidores" className="footer-list-item" />
                            </ListItemButton>
                            <ListItemButton component="a" href={RouteUser.STUDENT.path} className="bloque-estudiantes">
                                <ListItemText primary="Scifood para estudiantes" className="footer-list-item para-estudiantes" />
                                <ListItemText primary="Scifood para" className="footer-list-item para-estudiantes-salto" />
                                <ListItemText primary="estudiantes" className="footer-list-item para-estudiantes-salto" />
                            </ListItemButton>
                            <ListItemButton component="a" href={RouteUser.ABOUT_US.path}>
                                <ListItemText primary="Quiénes somos" className="footer-list-item" />
                            </ListItemButton>
                        </List>
                    </div>
                    <div>
                        <List>
                            <ListItemText primary="Productos" className="first-list-item" />
                            <ListItemButton component="a" href={RouteUser.APPERTFLOW.path}>
                                <ListItemText primary="AppertFlow" className="footer-list-item" />
                            </ListItemButton>
                            <ListItemButton component="a" href={RouteUser.LABNOTES.path}>
                                <ListItemText primary="LabNotes" className="footer-list-item" />
                            </ListItemButton>
                        </List>
                    </div>
                </div>
                <div className="copyright-container">
                    <span>Copyright © 2022 Scifood. All rights reserved. <Link href={RouteUser.PRIVACY_POLICY.path} color="inherit" underline="hover">Privacy Policy</Link> · <Link href={RouteUser.COOKIES_POLICY.path} color="inherit" underline="hover">Terms of Use</Link> · <Link href={RouteUser.LEGAL_CONDITIONS.path} color="inherit" underline="hover">Legal Policy</Link></span>
                </div>
            </div>
        </div>
    );
}
