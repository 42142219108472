import React from "react";
import {Button, Fade, ListItemIcon, Menu, MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";
import LanguageIcon from '@mui/icons-material/Language';

const lngs = {
    en: {nativeName: 'English'},
    es: {nativeName: 'Español'}
}

export default function MenuLanguage() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = async (lng) => {
        await i18n.changeLanguage(lng)
        await setAnchorEl(null)
        window.location.reload()
    }

    const {i18n} = useTranslation()

    return (
        <div className="menu-account">
            <Button
                id="language-button"
                aria-controls="menu-language"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                style={{color: "black"}}
            >
                <ListItemIcon>
                    <LanguageIcon style={{color: "black"}}/>
                </ListItemIcon>
                {i18n.resolvedLanguage}
            </Button>
            <Menu
                id="menu-language"
                MenuListProps={{
                    'aria-labelledby': 'language-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {Object.keys(lngs).map((lng) => (
                    <MenuItem onClick={() => handleClose(lng)}>{lngs[lng].nativeName}</MenuItem>
                ))}
            </Menu>
        </div>
    )
}
