import React, {useEffect, useState} from "react";
import {apiList as apiListCategory} from "../../../services/User/category";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {SectionsType} from "../../../utils/sectionsType";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Skeleton} from "@mui/material";

export default function Menu(props) {

    const {i18n} = useTranslation()
    const {typeSlug} = useParams()
    const [state, setState] = useState([])
    const [loading, setLoading] = useState(false)
    const {idSubCategory, setIdSubCategory, setHeaderType} = props

    useEffect(() => {
        getMenu()
    }, [])

    const getMenu = async () => {
        setLoading(true)
        let id = 0
        switch (typeSlug) {
            case "biografia":
                id = 8
                setHeaderType(SectionsType.biografia)
                break
            case "tutoriales":
                id = 10
                setHeaderType(SectionsType.tutoriales)
                break
            case "descargas":
                id = 11
                setHeaderType(SectionsType.descargas)
                break
            case "faq":
                id = 9
                setHeaderType(SectionsType.faq)
                break
            case "enlaces-de-interes":
                id = 12
                setHeaderType(SectionsType.enlacesDeInteres)
                break
            case "videos":
                id = 13
                setHeaderType(SectionsType.videos)
                break
        }
        await apiListCategory({
            id,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            setLoading(false)
            setState(resp.data)
        }).catch(err => {
            setLoading(false)
        })
    }

    const getPost = (idSubCategory = null, idCategory = null) => {
        setIdSubCategory(idSubCategory)

        // Recoremos state en busca de la categoria y la subcategoria
        state.forEach((item, key) => {
            if (item.id == idCategory) {
                item.active = true
                item.subcategories.forEach((itemSub, key) => {
                    if (itemSub.id == idSubCategory) {
                        itemSub.active = true
                    } else {
                        itemSub.active = false
                    }
                })
            } else {
                item.active = false
                item.subcategories.forEach((itemSub, key) => {
                    itemSub.active = false
                })
            }
        })
    }

    return (
        <div className="block2">

            {loading && (<LoadingMenu/>)}

            <div className="block2-container">
                {state.map((item, key) => {

                    let active = item.active ? "active" : "inactive"

                    return (
                        <span key={key}>
                            <Accordion className="first-list" defaultExpanded={ key === 0 && !idSubCategory ? true : false}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        id={`category-${item.id}`}
                                        className={`category-element category-${item.id} ${active}`}
                                    >{item.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {item.subcategories.length > 0 && (
                                        <>
                                            <ul className="second-list">
                                                {item.subcategories.map((subCategory, keySub) => {
                                                    if (keySub === 0 && key === 0 && !idSubCategory) {
                                                        getPost(subCategory.id, item.id)
                                                    }

                                                    let activeSub = subCategory.active ? "active" : "inactive"

                                                    return (
                                                        <span key={keySub}>
                                                            <li id={`subcategory-${subCategory.id}`}
                                                                className={`subcategory-element subcategory-${subCategory.id} ${activeSub}`}>
                                                                <a onClick={() => getPost(subCategory.id, item.id)}>{subCategory.name}</a>
                                                            </li>
                                                        </span>
                                                    )
                                                })}
                                            </ul>
                                        </>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </span>
                    )
                })}
            </div>
        </div>
    )
}

function LoadingMenu() {
    return (
        <>
            <Typography variant="h4"><Skeleton style={{width: 200}}/></Typography>
            <Typography variant="h4"><Skeleton/></Typography>
            <Typography variant="h4"><Skeleton/></Typography>
            <Typography variant="h4"><Skeleton/></Typography>
            <Typography variant="h4"><Skeleton/></Typography>
        </>
    )
}
