import * as React from 'react';
import Image1 from '../../../assets/Uploads/aboutUs/Gráfica_2_AppertFlow_Web.webp'

export default function Block3() {

    return (
        <div className="block3">
            <div className="block3-flex-container">
                <div className="block3-flex-div">
                    <img src={Image1} className="image1" />
                </div>
                <div className="block3-flex-div">
                    <p><span>SciFood Studio es un software de Gémina I+D</span>, una empresa perteneciente al Grupo Gémina, un grupo con una experiencia acumulada de más de 30 años en el sector del procesado y envasado de alimentos.</p>
                    <p>Desarrollado por expertos en la industria alimentaria para expertos en tecnología de alimentos.</p>
                </div>
            </div>
        </div>
    )
}
