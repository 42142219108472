import React, {useState} from "react"
import Button from '@mui/material/Button';
import Image8 from '../../../assets/Uploads/shutterstock_1868491405.jpg'
import Link from '@mui/material/Link';
import {RouteUser} from '../../../routes/RouteUser'
import SciFoodStudioExe from "../../../assets/Files/SciFood_Studio_2.exe";
import DownloadModal from "../../../components/Layouts/User/DownloadModal";

export default function Block5() {

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleClick = () => {
        setModalIsOpen(true)
    };

    return (
        <div className="block5">
            <DownloadModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
            <div className="block5-image-container">
                <div className="block5-image-filter"></div>
                <img src={Image8} />
            </div>
            <div className="block5-text">
                <h4>¡Descarga ya el primer software para validación y verificación de tratamientos térmicos de alimentos!</h4>
                <Link href={SciFoodStudioExe} download="SciFoodStudio.exe" onClick={handleClick} underline="none" color="inherit"><Button className="block5-button">Descargar SciFood Studio</Button></Link>
            </div>
        </div>
    )
}
