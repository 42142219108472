import React, {useState} from "react"
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Link from '@mui/material/Link';

import useAuth from '../../../../../hooks/useAuth';
import {RouteUser} from "../../../../../routes/RouteUser";
import {RouteAuth} from "../../../../../routes/RouteAuth";

import LogoGeminaProcesosAlimentarios from "../../../../../assets/logo-gemina-procesos-alimentarios.svg";
import LogoGeminaComponentes from "../../../../../assets/logo-gemina-store.jpg";
import SciFoodStudioExe from "../../../../../assets/Files/SciFood_Studio_2.exe";
import DownloadModal from "../../DownloadModal";

export default function MenuListComposition() {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
        } else if (event.key === 'Escape') {
        setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const {auth} = useAuth()

    let sessionButton = null

    if(auth){
        sessionButton = <Typography style={{ minWidth: 100, cursor: "pointer" }}>
            <Link href={RouteAuth.LOGOUT.path} underline="hover" style={{color:"#000000"}}>Cerrar sesión</Link>
        </Typography>
    }else{
        sessionButton = <Typography style={{ minWidth: 100, cursor: "pointer" }}>
            <Link href={RouteAuth.LOGIN.path} underline="hover" style={{color:"#000000"}}>Acceder</Link>
        </Typography>
    }

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleClick = () => {
        setModalIsOpen(true)
    };

    return (
        <div className="menu-mobile">
            <DownloadModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
            <Stack direction="row" spacing={2}>
                <div>
                    <Button
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className="header-icon-container"
                    >
                    <MenuIcon/>
                    </Button>
                    <Popper className="mobile-menu-list"
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                    >
                    {({ TransitionProps, placement }) => (
                        <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                        >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={handleListKeyDown}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                    <Typography sx={{ minWidth: 100, cursor: "pointer" }}>
                                        <Link href={RouteUser.ROOT.path} underline="hover" style={{color:"#000000"}}>Home</Link>
                                    </Typography>
                                    <Menu className="menu-list" menuButton={<MenuButton style={{
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        color: "#000000",
                                        minWidth: "auto",
                                        border: "none",
                                        background: "none",
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "nowrap"
                                    }}>Explorar<KeyboardArrowDownIcon className="header-icon" /></MenuButton>}>
                                        <MenuItem style={{ cursor: "pointer" }}>
                                            <Link href={RouteUser.TRAINING.path} underline="hover" style={{color:"#000000"}}>Formación</Link>
                                        </MenuItem>
                                        <MenuItem style={{ cursor: "pointer" }}>
                                            <Link href={RouteUser.DISTRIBUTOR.path} underline="hover" style={{color:"#000000"}}>Distribuidores</Link>
                                        </MenuItem>
                                        <MenuItem style={{ cursor: "pointer" }}>
                                            <Link href={RouteUser.STUDENT.path} underline="hover" style={{color:"#000000"}}>Scifood para estudiantes</Link>
                                        </MenuItem>
                                        <MenuItem style={{ cursor: "pointer" }}>
                                            <Link href={RouteUser.ABOUT_US.path} underline="hover" style={{color:"#000000"}}>Quiénes somos</Link>
                                        </MenuItem>
                                    </Menu>
                                    <Menu className="menu-list" menuButton={<MenuButton style={{
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        color: "#000000",
                                        minWidth: "auto",
                                        border: "none",
                                        background: "none",
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "nowrap"
                                    }}>Productos<KeyboardArrowDownIcon className="header-icon" /></MenuButton>}>
                                        <MenuItem style={{ cursor: "pointer" }}>
                                            <Link href={RouteUser.APPERTFLOW.path} underline="hover" style={{color:"#000000"}}>AppertFlow</Link>
                                        </MenuItem>
                                        <MenuItem style={{ cursor: "pointer" }}>
                                            <Link href={RouteUser.LABNOTES.path} underline="hover" style={{color:"#000000"}}>LabNotes</Link>
                                        </MenuItem>
                                    </Menu>
                                    <Typography style={{ minWidth: 100, cursor: "pointer" }}>
                                        <Link href={RouteUser.SUPPORT.path} underline="hover" style={{color:"#000000"}}>Soporte</Link>
                                    </Typography>
                                    <Typography style={{ minWidth: 100, cursor: "pointer" }} className="menu-solicitar-demo">
                                        <Link href={SciFoodStudioExe} download="SciFoodStudio.exe" onClick={handleClick} underline="hover" color="inherit">{'Descargar SciFood Studio'}</Link>
                                    </Typography>
                                    {sessionButton}
                                    <Typography style={{ minWidth: 100, cursor: "pointer" }} className="logo-gemina">
                                        <a href="https://www.gemina.es/es/" target="_blank"><img src={LogoGeminaProcesosAlimentarios} /></a>
                                    </Typography>
                                    <Typography style={{ minWidth: 100, cursor: "pointer" }} className="logo-gemina-componentes">
                                        <a href="https://www.geminacomponentes.com" target="_blank"><img src={LogoGeminaComponentes} /></a>
                                    </Typography>
                                </Box>
                            </MenuList>
                            </ClickAwayListener>
                        </Paper>
                        </Grow>
                    )}
                    </Popper>
                </div>
            </Stack>
        </div>
    );
}