import * as React from 'react';
import Image5 from '../../../assets/Uploads/student/shutterstock_745960519.webp'

export default function Block4() {


    return (
        <div className="block4">
            <div className="block4-content">
                <div className="block4-image-text-container">
                    <div className="block4-image-container">
                        <img src={Image5} className="block4-image" />
                    </div>
                    <div className="block4-text-container">
                        <h3>La experiencia práctica  constituye un activo innegable en el mercado laboral</h3>
                        <p>El uso de nuestro software puede enriquecer enormemente la actividad docente mediante la formación práctica de los alumnos.<br/><br/>SciFood Studio es un entorno de trabajo que facilita las decisiones que determinan la inocuidad y calidad de los alimentos y compila un paquete único de aplicaciones software altamente profesionales y fáciles de usar, enfocadas todas ellas en la aplicación del conocimiento científico más actual en los procesos industriales de fabricación de alimentos.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
