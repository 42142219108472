import * as React from 'react';

export default function Block2() {

    return (
        <div className="block2">
            <div className="block2-flex-container">
                <div className="block2-flex-div">
                    <p>En Gémina invertimos en tecnología y nuevos productos diseñados específicamente para nuestros clientes, en respuesta a sus necesidades y a las dinámicas del mercado.</p>
                </div>
                <div className="block2-flex-div">
                    <p>Nuestros clientes necesitan soluciones rápidas, seguras y adaptadas a las circunstancias actuales, que les permitan reducir sus costes y aumentar su productividad, poniendo énfasis en la seguridad y calidad alimentaria.</p>
                </div>
            </div>
        </div>
    )
}
