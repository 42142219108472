import * as React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {Box} from '@mui/material';
import {useEffect} from "react";
import ReactGA from "react-ga4";
export default function NotFoundPage(props) {

    useEffect(() => {
        ReactGA.event({
            category: "Page",
            action: `NotFoundPage`,
        });
    },[])

    return (
        <ThemeProvider >
            <Box sx={{display: 'flex'}}>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <div style={{"height": "20vh"}}></div>

                    <h1 style={{"font-size": "130px", "margin": "0px", textAlign: "center"}}>404</h1>
                    <h2 style={{textAlign: "center"}}>Página no encontrada</h2>
                </Box>
            </Box>
        </ThemeProvider>
    )
}
