import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import {useEffect, useState} from "react";

const filter = createFilterOptions();

/**
 * Muestra un selector
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **value** *string*
 * - **options** *object*
 * - **required** *bool*
 * - **disabled** *bool*
 * - **onChange** *function*
 *
 * ## EJ
 * options: [{id: 1, name: "Aaaa"}, {id: 2, name: "Bbbbb"}]
 */
export default function InputAutoComplete(props) {

    const {label, name, options, required, disabled, value, onChange} = props

    const [valueInput, setValueInput] = useState({})
    const [loading, setLoading] = useState(true)
    const [disabledIn, setDisabledIn] = useState(disabled)

    // Selecionamos los elementos por defecto
    useEffect(async () => {
        setLoading(true)
        if (value) {
            await getOptionObject(value)
            setLoading(false)
        } else {
            setValueInput(null)
            setLoading(false)
        }
    }, [options, value])

    const handleChange = (e, newValue) => {
        if (newValue !== undefined) {
            onChange({target: {name, value: newValue?.id}})
        }
    }

    const getOptionObject = async (id) => {
        setDisabledIn(true)
        await options.forEach((item) => {
            if (id === item.id) {
                setValueInput({id: item.id, name: item.name})
            }
        })
        setDisabledIn(false)
    }

    if (loading) {
        return <div>Cargando...</div>
    }
    return (
        <>
            <Autocomplete
                defaultValue={valueInput}
                multiple={false}
                disabled={disabledIn}
                onChange={handleChange}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const {inputValue} = params;
                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={options}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option.name;
                    }
                    // Regular option
                    return option.name;
                }}
                renderOption={(props, option, {selected}) => (
                    <li {...props}>
                        {option.name}
                    </li>
                )}
                freeSolo
                size="small"
                required={required}
                name={name}
                renderInput={(params) => (
                    <TextField {...params} label={label}/>
                )}
            />
        </>
    )
}

