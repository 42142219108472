import React, {useState} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

/**
 * Muestra un botón para subir ficheros
 *
 * ## PROPS
 * - **elementDnd** *object (useState)*   Ej: [{id: "1", content: "Item 1" }, {id: "2", content: "Item 2" }, {id: "1", content: "Item 3" }]
 * - **setElementDnd** *object (useState)*
 * - **handleClick** *function*
 */
export default function DndElements(props) {
    const {elementDnd, setElementDnd, handleClick} = props

    const onDragEnd = (result) => {
        // Caído fuera de la lista
        if (!result.destination) {
            return;
        }

        const items = reorder(elementDnd, result.source.index, result.destination.index);

        setElementDnd(items)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                    >
                        {elementDnd.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <>
                                        <div
                                            onClick={() => handleClick(item.id)}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            className={`dnd-element`}
                                        >
                                            {item.content}
                                        </div>
                                    </>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

// Una pequeña función para ayudarnos a reordenar el resultado
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // algunos estilos básicos para que los artículos se vean un poco mejor
    userSelect: 'none',
    margin: `0 ${grid}px 0 0`,
    padding: 4,

    // cambiar el color de fondo si se arrastra
    background: isDragging ? 'lightgreen' : 'rgb(255, 255, 255)',
    border: "1px solid rgb(218, 218, 218)",

    // estilos que necesitamos aplicar en arrastrables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    display: 'flex',
    padding: grid,
    overflow: 'auto'
});

