import React from "react";
import ReactPlayer from "react-player";

export default function VideoBlock(props) {
    const {video} = props;

    return (
        <>
            <div className="videos-video">
                <ReactPlayer
                    url={video}
                    className='react-player'
                    width='100%'
                    height='100%'
                    controls={true}
                    playIcon={<button>Play</button>}
                />
            </div>
        </>
    )
}
