import * as React from 'react';
import AppertflowIsotipo from '../../../assets/Uploads/appertflow-isotipo-1.png'
import LabnotesIsotipo from '../../../assets/Uploads/labnotes-isotipo-1.png'
import Image2 from '../../../assets/Uploads/img_2.jpg'
import Image3 from '../../../assets/Uploads/home/shutterstock_2000268743.webp'

export default function Block4() {

    return (
        <div className="block4">
            <div className="productos">Productos</div>
            <div className="blocks-container">
                <div className="block-content">
                    <div className="image-container">
                        <img src={Image2} />
                    </div>
                    <div className="block-content-texts">
                        <div className="block-content-img-container">
                            <img src={AppertflowIsotipo} />
                        </div>
                        <div className="block-texts">
                            <h4>AppertFlow</h4>
                            <h5>Potencia la seguridad y calidad de los alimentos pasteurizados</h5>
                            <p>AppertFlow es un software para la <span>Validación, Vigilancia y Verificación en tiempo real</span> de la medida de control que se establece en la pasteurización en continuo de alimentos líquidos.</p>
                            <p>Valida y verifica con facilidad cada lote de producto pasteurizado asegurando la trazabilidad y la seguridad alimentaria, y <span>optimiza</span> los procesos térmicos para obtener la máxima calidad de producto.</p>
                        </div>
                    </div>
                </div>
                <div className="block-content">
                    <div className="image-container">
                        <img src={Image3} />
                    </div>
                    <div className="block-content-texts">
                        <div className="block-content-img-container">
                            <img src={LabnotesIsotipo} />
                        </div>
                        <div className="block-texts">
                            <h4>LabNotes</h4>
                            <h5>Optimiza la gestión de la información de tu laboratorio</h5>
                            <p>LabNotes es una herramienta de anotación y registro de información analítica: fisicoquímica, microbiológica y sensorial, sobre los alimentos en todos sus estados de elaboración durante su tránsito por la fábrica.</p>
                            <p>Garantiza la <span>trazabilidad</span> de los procesos de elaboración de alimentos y realiza un seguimiento y control evolutivo de los parámetros de seguridad y calidad.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}