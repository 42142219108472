import Block1 from "../../../components/User/AppertFlow/block1"
import Block2 from "../../../components/User/AppertFlow/block2"
import Block3 from "../../../components/User/AppertFlow/block3"
import Block4 from "../../../components/User/AppertFlow/block4"
import {useEffect} from "react";
import ReactGA from "react-ga4";

export default function AppertFlow() {

    useEffect(() => {
        ReactGA.event({
            category: "Page",
            action: `AppertFlow`,
        });
    },[])

    return (
        <div className="appertflow">
            <Block1 />
            <Block2 />
            <Block3 />
            <Block4 />
        </div>
    );
}
