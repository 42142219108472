import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {apiGetPost} from "../../../../services/Admin/post";
import {apiUpload, apiGetOne, apiMediaDetail, apiMediaOrder, apiDelete} from "../../../../services/Admin/media";
import {Button, Grid, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import DndElements from "../../../../components/DndElements";
import Header from "./Header";
import ButtonFile from "../../../../components/Inputs/ButtonFile";
import LoadingBar from "../../../../components/LoadingBar";
import clone from "../../../../utils/clone";
import InputText from "../../../../components/Inputs/InputTextAdmin";
import Modal from "../../../../components/Modal";
import InputSelect from "../../../../components/Inputs/InputSelect";
import {lang} from "../../../../utils/lang";
import LoadingCircle from "../../../../components/LoadingCircle";
import ReactPlayer from "react-player";

export default function Media() {
    const {id} = useParams()
    const {t} = useTranslation()
    const {i18n} = useTranslation()

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)
    const [elements, setElementDnd] = useState([])
    const [open, setOpen] = useState(false)
    const [elementSelect, setElementSelect] = useState(null)
    const [trans, setTrans] = useState({})

    const myRef = useRef(null)

    useEffect(() => {
        setState({...state, loading: true})
        const timeOutId = setTimeout(() => {
            setReorderImage()
        }, 1000)
        return () => clearTimeout(timeOutId)
    }, [elements])

    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        getMedia()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const setReorderImage = () => {
        // Obtener el id del json
        let mediaId = elements.map(item => item.id)

        apiMediaOrder({
            id,
            lang: i18n.resolvedLanguage,
            mediaId: mediaId
        }).then(resp => {
            setState({...state, loading: false})
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setState({...state, loading: false})
        })
    }

    const getMedia = () => {
        setMsgError([])
        setState({...state, loading: true})

        apiGetPost({
            id,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            let temp = resp.data.media.map(doc => {
                let contant = <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "150px"}}><img src={doc.url} style={{width: "100%", maxHeight: "150px", padding: "5px"}} /></div>
                if (doc.type === "mp4") {
                    contant = <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "150px"}}><ReactPlayer
                        url={doc.url}
                        className='react-player'
                        playing
                        width="100%"
                        height="100%"
                    /></div>
                }
                return ({
                    id: `${doc.id}`,
                    content: contant
                })
            })
            setElementDnd(temp)
            setState({...state, loading: false})
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setState({...state, loading: false})
        })
    }

    const handleChangeMedia = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
        handleSubmit()
    }

    const handleSubmit = () => {
        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        updateImage()
    }

    const updateImageDatails = () => {
        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        apiMediaDetail({
            id: elementSelect,
            lang: i18n.resolvedLanguage,
            trans: trans
        }).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
            setLoadingImage(false)
            setOpen(false)
            setTrans({})
            getMedia()
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const updateImage = () => {
        setLoadingImage(true)

        let f = new FormData()
        for (let value of state.newImage) {
            f.append("file[]", value.binary)
            f.append("idPost[]", id)
        }

        apiUpload(f, i18n.resolvedLanguage).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
            setLoadingImage(false)
            getMedia()
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const handleClick = (id) => {
        setOpen(true)
        setElementSelect(id)
    }

    return (
        <>
            <div ref={myRef}></div>

            <Modal
                title={""}
                btnOk={"Guardar"}
                btnCancel={"Cancelar"}
                open={open}
                setOpen={setOpen}
                handleClick={updateImageDatails}
                fullWidth={true}
            >
                <ViewModal
                    elementSelect={elementSelect}
                    getMedia={getMedia}
                    setOpen={setOpen}
                    setReorderImage={setReorderImage}
                    setMsgSuccess={setMsgSuccess}
                    trans={trans}
                    setTrans={setTrans}
                />
            </Modal>

            <Header
                state={state}
                setState={setState}
                id={id}
                msgError={msgError}
                msgSuccess={msgSuccess}
                trans={false}
            />

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{display: 'flex', flexDirection: 'column', borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
                        <LoadingBar loading={state.loading} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            {elements.length > 0 && (
                                <>
                                    <Grid item xs={8}>
                                        <h5 style={{textAlign: 'right', margin: 10}}>Formatos aceptados .png, .jpg, .jpeg, .mp4</h5>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonFile
                                            label="+ Añadir imagen"
                                            loading={loadingImage}
                                            required
                                            name="newImage"
                                            onChange={handleChangeMedia}
                                            multiple={true}
                                            accept="image/png, image/jpeg, video/mp4"
                                        />

                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={3}>

                                    {(elements.length === 0 && !state.loading) && (
                                        <>
                                            <h3 style={{textAlign: 'center', width: '100%', paddingTop: 30}}>No hemos encontrado ninguna imagen</h3>

                                            <div style={{marginLeft: 'auto', marginRight: 'auto', width: '300px'}}>
                                                <ButtonFile
                                                    label="+ Añadir imagen"
                                                    loading={loadingImage}
                                                    required
                                                    name="newImage"
                                                    onChange={handleChangeMedia}
                                                    multiple={true}
                                                    accept="image/png, image/jpeg, video/mp4"
                                                />
                                                <h5 style={{textAlign: 'center'}}>Formatos aceptados <br/> .png, .jpg, .jpeg, .mp4</h5>
                                            </div>
                                        </>
                                    )}

                                    <Grid item xs={12}>
                                        <DndElements
                                            elementDnd={elements}
                                            setElementDnd={setElementDnd}
                                            handleClick={handleClick}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

function ViewModal(props) {

    const {elementSelect, getMedia, setOpen, setReorderImage, setMsgSuccess, trans, setTrans} = props
    const [urlImage, setUrlImage] = useState(null)
    const [langSelect, setLangSelect] = useState("es")
    const {i18n} = useTranslation()
    const [loading, setLoading] = useState(true)

    const handleChange = (event) => {
        trans[langSelect][event.target.name] = event.target.value
        setTrans({...trans})
    }

    const handleChangeLang = (event) => {
        setLangSelect(event.target.value)
    }

    useEffect(() => {
        if (elementSelect){
            getMediaDetail()
        }
    }, [elementSelect])

    useEffect(() => {
        setTrans({})

    }, [])

    const getMediaDetail = () => {
        setLoading(true)
        apiGetOne({
            id: elementSelect,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            if (!resp.data?.trans){
                setTrans({...trans, [langSelect]: clone({ name: '' })})
            } else {
                setTrans(resp.data?.trans || [])
            }
            let contant = <img src={resp.data?.url} style={{height: "100%", width: "200px"}} />
            if (resp.data?.type === "mp4") {
                contant = <ReactPlayer
                    url={resp.data?.url}
                    className='react-player'
                    playing
                    width='100%'
                    height='100%'
                    controls={true}
                />
            }
            setUrlImage(contant)
            setLoading(false)
        }).catch(err => {
            console.error(err)
            setLoading(false)
        })
    }

    // Si no existe el idioma iniciamos el modelo. Es decir, montamos estructura
    useEffect(() => {
        if (!trans[langSelect]) {
            setTrans({...trans, [langSelect]: clone({ name: '' })})
        }
    }, [langSelect])

    const handleRemoveMedia = () => {
        apiDelete({
            id: elementSelect,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            setOpen(false)
            getMedia()
            setReorderImage()
            setMsgSuccess(resp.data?.msg)
        }).catch(err => {
            console.error(err)
            setLoading(false)
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <LoadingCircle show={loading}/>
            </Grid>
            {!loading && (
                <>
                    <Grid item xs={5}>{urlImage}</Grid>
                    <Grid item xs={7}>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <InputSelect
                                    label={"Idioma"}
                                    name="langSelect"
                                    onChange={handleChangeLang}
                                    value={langSelect}
                                    options={lang}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InputText
                                    label="Nombre"
                                    required
                                    name="name"
                                    value={trans[langSelect]?.name}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12}><hr/></Grid>

                            <Grid item xs={12}>
                                <Button
                                    fullWidth={true}
                                    variant={"outlined"}
                                    color="error"
                                    onClick={handleRemoveMedia}
                                >Eliminar</Button>
                            </Grid>

                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    )
}

function initialValues(props) {
    return {
        id: props?.id || "",
        langSelect: props?.langSelect || "es",
        type: props?.type || 1,
        newImage: {},
        loading: true
    }
}
