import Header from "./Header"
import Footer from "./Footer"
import AdminBar from "../Admin/AdminBar";

export default function Basic(props) {

    const { children } = props;

    return (
        <div>
            <AdminBar />
            <Header/>
            {children}
            <Footer/>
        </div>
    );
}
