import React, {useState} from "react"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Link from '@mui/material/Link';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/core.css';

import useAuth from '../../../../../hooks/useAuth';
import {RouteUser} from "../../../../../routes/RouteUser";
import {RouteAuth} from "../../../../../routes/RouteAuth";

import LogoGeminaProcesosAlimentarios from "../../../../../assets/logo-gemina-procesos-alimentarios.svg";
import LogoGeminaComponentes from "../../../../../assets/logo-gemina-store.jpg";
import SciFoodStudioExe from "../../../../../assets/Files/SciFood_Studio_2.exe";
import DownloadModal from "../../DownloadModal";

export default function AccountMenu() {

    const {auth} = useAuth()

    const role = auth ? auth.role : null

    let sessionButton = null

    if(auth){
        sessionButton = <Typography style={{ minWidth: 100, cursor: "pointer" }}>
            <Link href={RouteAuth.LOGOUT.path} underline="hover" style={{color:"#000000"}}>Cerrar sesión</Link>
        </Typography>
    }else{
        sessionButton = <Typography style={{ minWidth: 100, cursor: "pointer" }}>
            <Link href={RouteAuth.LOGIN.path} underline="hover" style={{color:"#000000"}}>Acceder</Link>
        </Typography>
    }

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleClick = () => {
        setModalIsOpen(true)
    };

    return (
        <div className="menu-desktop">
            <DownloadModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
            <div className="clientes-container display-none">
                <Typography sx={{ minWidth: 100, cursor: "pointer" }}>Clientes</Typography>
            </div>
            <div className="menu-links">
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    <Typography sx={{ minWidth: 100, cursor: "pointer" }}>
                        <Link href={RouteUser.ROOT.path} underline="hover" style={{color:"#000000"}}>Home</Link>
                    </Typography>
                    <Menu className="menu-list" menuButton={<MenuButton style={{
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "#000000",
                        minWidth: "auto",
                        marginLeft: "50px",
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "nowrap"
                    }}>Explorar<KeyboardArrowDownIcon className="header-icon" /></MenuButton>}>
                        <Link href={RouteUser.TRAINING.path} underline="hover" style={{color:"#000000"}}>
                            <MenuItem style={{ cursor: "pointer" }}>Formación</MenuItem>
                        </Link>
                        <Link href={RouteUser.DISTRIBUTOR.path} underline="hover" style={{color:"#000000"}}>
                            <MenuItem style={{ cursor: "pointer" }}>Distribuidores</MenuItem>
                        </Link>
                        <Link href={RouteUser.STUDENT.path} underline="hover" style={{color:"#000000"}}>
                            <MenuItem style={{ cursor: "pointer" }}>Scifood para estudiantes</MenuItem>
                        </Link>
                        <Link href={RouteUser.ABOUT_US.path} underline="hover" style={{color:"#000000"}}>
                            <MenuItem style={{ cursor: "pointer" }}>Quiénes somos</MenuItem>
                        </Link>
                    </Menu>
                    <Menu className="menu-list" menuButton={<MenuButton style={{
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "#000000",
                        minWidth: "auto",
                        marginLeft: "50px",
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "nowrap"
                    }}>Productos<KeyboardArrowDownIcon className="header-icon" /></MenuButton>}>
                        <Link href={RouteUser.APPERTFLOW.path} underline="hover" style={{color:"#000000"}}>
                            <MenuItem style={{ cursor: "pointer" }}>AppertFlow</MenuItem>
                        </Link>
                        <Link href={RouteUser.LABNOTES.path} underline="hover" style={{color:"#000000"}}>
                            <MenuItem style={{ cursor: "pointer" }}>LabNotes</MenuItem>
                        </Link>
                    </Menu>
                    <Typography style={{ minWidth: 100, cursor: "pointer" }}>
                        <Link href={RouteUser.SUPPORT.path} underline="hover" style={{color:"#000000"}}>Soporte</Link>
                    </Typography>
                    <Typography style={{ minWidth: 100, cursor: "pointer" }} className="menu-solicitar-demo">
                        <Link href={SciFoodStudioExe} download="SciFoodStudio.exe" onClick={handleClick} underline="hover" color="inherit">{'Descargar SciFood Studio'}</Link>
                    </Typography>
                    {sessionButton}
                    <Typography style={{ minWidth: 100, cursor: "pointer" }} className="logo-gemina">
                        <a href="https://www.gemina.es/es/" target="_blank"><img src={LogoGeminaProcesosAlimentarios} /></a>
                    </Typography>
                    <Typography style={{ minWidth: 100, cursor: "pointer" }} className="logo-gemina-componentes">
                        <a href="https://www.geminacomponentes.com" target="_blank"><img src={LogoGeminaComponentes} /></a>
                    </Typography>
                </Box>
            </div>
        </div>
    );
}