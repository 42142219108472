import React, {useEffect, useState} from "react";
import {apiList} from "../../../../services/Admin/post";
import {Button, Grid, IconButton, Paper} from "@mui/material";
import MsgError from "../../../../components/MsgError";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import Table from "../../../../components/Table";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import InputText from "../../../../components/Inputs/InputTextAdmin";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import InputAutocomplete from "../../../../components/Inputs/InputAutocomplete";
import InputAutocompleteMultiple from "../../../../components/Inputs/InputAutocompleteMultiple";
import {apiList as apiListType} from "../../../../services/Admin/type";
import {apiList as apiListCategory} from "../../../../services/Admin/category";
import {apiList as apiListSubcategory} from "../../../../services/Admin/subcategory";
export default function Management() {
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [optionsType, setOptionsType] = useState([])
    const [optionsCategory, setOptionsCategory] = useState([])
    const [optionsSubcategory, setOptionsSubcategory] = useState([])
    const {i18n} = useTranslation()

    useEffect(() => {
        getSelectorType()
        getSelectorCategory()
        getSelectorSubcategory()
    }, [])

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field

        let params = {
            lang: i18n.resolvedLanguage,
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.id) { params.id = state.id }
        if (state.author) { params.author = state.author }
        if (state.subcategory) { params.subcategory = state.subcategory }
        if (state.category) { params.category = state.category }
        if (state.type) { params.type = state.type }

        apiList(params).then(resp => {
            setRowCount(resp.data?.count)

            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc,
                    isActive: doc.isActive ? "Si" : "No",
                    isVisible: doc.isActive ? "Si" : "No",
                }
            })
            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }


    const getSelectorType = () => {
        apiListType({
            lang: i18n.resolvedLanguage,
            limit: 1000,
            offset: state.offset ?? 0,
            field: 'name',
            sort: 'desc'
        }).then(resp => {
            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc
                }
            })
            setOptionsType(temp)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    const getSelectorCategory = () => {
        apiListCategory({
            lang: i18n.resolvedLanguage,
            limit: 1000,
            offset: state.offset ?? 0,
            field: 'name',
            sort: 'desc'
        }).then(resp => {
            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc
                }
            })
            setOptionsCategory(temp)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    const getSelectorSubcategory = () => {
        apiListSubcategory({
            lang: i18n.resolvedLanguage,
            limit: 1000,
            offset: state.offset ?? 0,
            field: 'name',
            sort: 'desc'
        }).then(resp => {
            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc
                }
            })
            setOptionsSubcategory(temp)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    return (
        <>
            <HeaderPage title="Contenido">
                <Button
                    variant="contained"
                    color="primary"
                    style={{float: "right", margin: "20px 0px"}}
                    href={RouteAdmin.POST_CREATE.path}
                >
                    + Añadir
                </Button>
            </HeaderPage>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MsgError msg={msgError}/>
                </Grid>

                <Grid item xs={12}>

                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>

                        <Filters
                            state={state}
                            setState={setState}
                            optionsType={optionsType}
                            optionsCategory={optionsCategory}
                            optionsSubcategory={optionsSubcategory}
                        />

                        <Table
                            columns={tableColumns()}
                            rows={rows}
                            rowCount={rowCount}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />

                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        id: "",
        author: "",
        type: "",
        category: "",
        subcategory: "",
    }
}

function tableColumns() {
    return [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            sortable: false,
        },
        {
            field: "author",
            headerName: "AUTHOR",
            flex: 2,
            sortable: false,
        },
        {
            field: "name",
            headerName: "NOMBRE",
            flex: 2,
            sortable: false,
        },
        {
            field: "isActive",
            headerName: "ACTIVO",
            flex: 1,
            sortable: false,
        },
        {
            field: "isVisible",
            headerName: "VISIBLE",
            flex: 1,
            sortable: false,
        },
        {
            field: "actions",
            headerName: "ACCIONES",
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`${RouteAdmin.POST_EDIT_GENERAL.path}/${params.id}`}>
                            <IconButton size="small">
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Link>
                    </>
                )
            },
        }
    ]
}

function Filters(props) {

    const {state, setState, optionsType, optionsCategory, optionsSubcategory} = props

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    return (
        <Grid container spacing={1} style={{marginBottom: '20px'}}>
            <Grid item xs={4}>
                <InputText
                    label="ID"
                    name="id"
                    value={state.id}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={8}>
                <InputText
                    label="Autor"
                    name="author"
                    value={state.author}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={4}>
                <InputAutocomplete
                    label="Tipo"
                    name="type"
                    onChange={handleChange}
                    value={state?.type}
                    options={optionsType}
                />
            </Grid>

            <Grid item xs={4}>
                <InputAutocompleteMultiple
                    label="Categoria"
                    name="category"
                    onChange={handleChange}
                    value={state?.category}
                    options={optionsCategory}
                />
            </Grid>

            <Grid item xs={4}>
                <InputAutocompleteMultiple
                    label="Subcategory"
                    name="subcategory"
                    onChange={handleChange}
                    value={state?.subcategory}
                    options={optionsSubcategory}
                />
            </Grid>
        </Grid>
    )
}
