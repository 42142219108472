import * as React from 'react';
import Button from '@mui/material/Button';
import Image5 from '../../../assets/Uploads/distributor/shutterstock_338471630_distribuidores.webp'

export default function Block2(props) {

    const {scrollToRef} = props

    const executeScroll = () => scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  

    return (
        <div className="block2">
            <div className="block2-content">
                <div className="block2-text-bottom">
                    <h4>Únete a la comunidad que está revolucionando el sector alimentario.</h4>
                    <Button className="solicitar-button" onClick={executeScroll}>Contactar</Button>
                </div>
                <div className="block2-image-text-container">
                    <div className="block2-image-container">
                        <img src={Image5} className="block2-image" />
                    </div>
                    <div className="block2-text-container">
                        <h3>Nuestros partners</h3>
                        <p>Si eres una firma de consultoría en calidad y seguridad alimentaria o vendedor de tecnología y software, contacta con nosotros.<br/><br/>SciFood busca activamente colaboradores y representantes que promocionen sus productos alrededor del mundo.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
