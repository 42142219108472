import useAuth from "../../hooks/useAuth";
import {useEffect} from "react";
import { useHistory } from "react-router-dom";
import {RouteAuth} from "../../routes/RouteAuth"

export default function CheckRole(props) {

    const {roles} = props
    const {auth} = useAuth()
    const history = useHistory()

    if(!roles.includes("ROLE_PUBLIC")){
        if (roles && auth) {
            if (auth.secondaryUser?.role !== "ROLE_ADMIN") {
                if (!roles.includes(auth.primaryUser?.role) && !roles.includes(auth.secondaryUser?.role)) {
                    history.push(RouteAuth.LOGIN.path, {replace: true})
                }
            }
        } else if(roles && !auth)  {
            history.push(RouteAuth.LOGIN.path, { replace: true })
        }
    }

    return (<></>)
}
