import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {apiGetPost, apiUpdateContent} from "../../../../services/Admin/post";
import {Grid, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import EditorText from "../../../../components/EditorText";
import Header from "./Header";
import clone from "../../../../utils/clone";
import LoadingBar from "../../../../components/LoadingBar";

export default function Content() {
    const {id} = useParams()
    const {t} = useTranslation()
    const {i18n} = useTranslation()

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [isChange, setIsChange] = useState(false)
    const [text, setText] = useState("")

    const myRef = useRef(null)

    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        getContent()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Si no existe el idioma iniciamos el modelo. Es decir, montamos estructura
    useEffect(() => {
        if (!state.trans[state.langSelect]) {
            setState({...state, trans: {...state.trans, [state.langSelect]: clone(state.model)}})
        }
        setText(state.trans[state.langSelect]?.text || "")
        setIsChange(true)
    }, [state.langSelect])

    useEffect(() => {
        console.clear()
        console.log("----")
        console.log(state.trans[state.langSelect]?.text)
        console.log("....")
        console.log(text)
    }, [text])

    const getContent = () => {
        setMsgError([])

        apiGetPost({
            id,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            let trans = resp.data?.content?.trans
            setState({...state, id, loading: false, trans: trans})

            // Volcamos el idoma por defecto
            if (trans) {
                if (trans[state.langSelect]?.text) {
                    setText(trans[state.langSelect]?.text)
                    setIsChange(true)
                }
            }
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setState({...state, loading: false})
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        updatePost()
    }

    const updatePost = () => {
        apiUpdateContent({
            id,
            trans: state.trans,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    // Actualizamos el texto en el state
    useEffect(() => {
        if (!state["trans"][state.langSelect]) {
            state["trans"][state.langSelect] = {}
        }

        if (!state["trans"][state.langSelect]["text"]) {
            state["trans"][state.langSelect]["text"] = ""
        }

        state["trans"][state.langSelect]["text"] = text
        setState({...state})
    }, [text])

    return (
        <>
            <div ref={myRef}></div>

            <Header
                state={state}
                setState={setState}
                id={id}
                msgError={msgError}
                msgSuccess={msgSuccess}
            />

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0
                    }}>
                        <LoadingBar loading={state.loading} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={3}>

                                    <Grid item xs={12}>
                                        <EditorText
                                            contant={text}
                                            setContent={setText}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}></Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <LoadingButton
                        onClick={handleSubmit}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        fullWidth
                    >
                        {id ? t('Modificar') : t('Crear')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}

const textInit = `<p>Esto es el texto del editor</p>
<img src="https://source.unsplash.com/8xznAGy4HcY/800x400" />
`

function initialValues(props) {
    return {
        id: props?.id || "",
        langSelect: props?.langSelect || "es",
        type: props?.type || 4,
        trans: props?.trans || {},
        model: {
            text: "",
        },
        loading: true
    }
}

