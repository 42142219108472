import * as React from 'react';

export default function Block2() {

    return (
        <div className="block2">
            <div className="block2-flex-container">
                <div className="block2-flex-div">
                    <p>LabNotes es la app de SciFood Studio que registra la información analítica de los alimentos en todos sus estados durante su tránsito por la fábrica.</p>
                </div>
                <div className="block2-flex-div">
                    <p>Almacena toda la información registrada en un único sitio, evitando la dispersión. Permite hacer un uso eficiente y transversal de la información, al integrarse con el resto de aplicaciones de SciFood Studio</p>
                </div>
            </div>
        </div>
    )
}
