import {useState} from "react"
import Block1 from "../../../components/User/Student/block1"
import Block2 from "../../../components/User/Student/block2"
import Block3 from "../../../components/User/Student/block3"
import Block4 from "../../../components/User/Student/block4"
import Block5 from "../../../components/User/Student/block5"
import {useEffect} from "react";
import ReactGA from "react-ga4";
export default function Student() {

    const [scrollToRef, setScrollToRef] = useState({})

    useEffect(() => {
        ReactGA.event({
            category: "Page",
            action: `Student`,
        });
    },[])

    return (
        <div className="student">
            <Block1 />
            <Block2 />
            <Block3 scrollToRef={scrollToRef} />
            <Block4 />
            <Block5 setScrollToRef={setScrollToRef} />
        </div>
    );
}
