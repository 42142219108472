import {TOKEN} from "../../utils/constants"
import SecureLS from "secure-ls";

function LocalStorage() {
    if (typeof window !== 'undefined') {
        return new SecureLS({'encodingType': 'aes'});
    } else {
        return null;
    }
}

export function setToken(token) {

    let ls = LocalStorage();
    ls?.set(TOKEN, token);

}

export function getToken() {

    let ls = LocalStorage();

    return ls?.get(TOKEN)
}

export function removeToken() {

    let ls = LocalStorage();
    ls?.remove(TOKEN)

}

export function hasExpiredToken() {
    const tokenDecode = getToken();
    const expireDate = tokenDecode.exp * 1000
    const currentDate = new Date().getTime()

    return currentDate > expireDate;

}