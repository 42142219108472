import React from "react";
import "./scss/global.scss";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Auth from "./auth"
import NotFoundPage from "./pages/User/NotFoundPage";
import {RouteAdmin} from "./routes/RouteAdmin";
import {RouteAuth} from "./routes/RouteAuth";
import {RouteUser} from "./routes/RouteUser";
import {Helmet} from "react-helmet";
import CheckRole from "./components/Auth/CheckRole";
import LayoutUser from "./components/Layouts/User";
import LayoutAdmin from "./components/Layouts/Admin";
import ReactGA from "react-ga4";
import {GOOGLE__ANALYTICS_ID} from "./utils/constants";
export default function App() {

    ReactGA.initialize(GOOGLE__ANALYTICS_ID);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Custom Title" });

    return (
        <Router>
            <Auth>
                <Switch>
                    {Object.keys(RouteAuth).map((item, key) => {
                        const e = RouteAuth[item]
                        return (
                            <Route exact key={key} path={e.path} render={(props) => (
                                <LayoutUser>
                                    <Helmet>
                                        <title>{e.title}</title>
                                    </Helmet>

                                    <CheckRole roles={e.roles}/>
                                    <e.page {...props} />
                                </LayoutUser>
                            )}/>
                        )
                    })}

                    {Object.keys(RouteUser).map((item, key) => {
                        const e = RouteUser[item]
                        return (
                            <Route exact key={key} path={`${e.path}${e.params ?? ""}`} render={(props) => (
                                <LayoutUser>
                                    <Helmet>
                                        <title>{e.title}</title>
                                    </Helmet>
                                    <CheckRole roles={e.roles}/>
                                    <e.page {...props} />
                                </LayoutUser>
                            )}/>
                        )
                    })}

                    {Object.keys(RouteAdmin).map((item, key) => {
                        const e = RouteAdmin[item]
                        return (
                            <Route exact key={key} path={`${e.path}${e.params ?? ""}`} render={(props) => (
                                <LayoutAdmin menu={e.menu}>
                                    <Helmet>
                                        <title>{e.title}</title>
                                    </Helmet>
                                    <CheckRole roles={e.roles}/>
                                    <e.page {...props} />
                                </LayoutAdmin>
                            )}/>
                        )
                    })}

                    <Route path='*' component={NotFoundPage}/>
                </Switch>
            </Auth>
        </Router>
    )
}
