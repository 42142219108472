import Block1 from "../../../components/User/DemoRequest/block1"
import {useEffect} from "react";
import ReactGA from "react-ga4";

export default function DemoRequest() {

    useEffect(() => {
        ReactGA.event({
            category: "Page",
            action: `DemoRequest`,
        });
    },[])

    return (
        <div className="demo-request">
            <Block1 />
        </div>
    );
}
