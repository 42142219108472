import * as React from 'react';
import Image1 from '../../../assets/Uploads/aboutUs/shutterstock_1659535873_ciencia_para_un_mundo_mejor.webp'
import Image2 from '../../../assets/Uploads/aboutUs/shutterstock_2191250035_go_beyond_established.webp'
import Image3 from '../../../assets/Uploads/aboutUs/shutterstock_33446842_good_food_safe_food.webp'

export default function Block4() {

    return (
        <div className="block4">
            <div className="block4-divider" ></div>
            <div className="block4-flex-container">
                <div className="block4-flex-div">
                    <div>
                        <img src={Image1} />
                    </div>
                    <p><span>SciFood for a safer world</span><br/><br/>Un compromiso con el continuo desarrollo de productos que ayuden a nuestros clientes a satisfacer las necesidades alimentarias de millones de personas.</p>
                </div>
                <div className="block4-flex-div">
                    <div>
                        <img src={Image2} />
                    </div>
                    <p><span>Go beyond established</span><br/><br/>Buscamos soluciones que vayan más allá de lo simplemente requerido o establecido, que optimicen la calidad de los alimentos y pongan el foco en la seguridad alimentaria.</p>
                </div>
                <div className="block4-flex-div">
                    <div>
                        <img src={Image3} />
                    </div>
                    <p><span>Good Food, Safe Food, SciFood</span><br/><br/>Es el objetivo que conduce nuestra organización y el fiel reflejo de nuestro propósito:  fabricar alimentos seguros y de calidad, en el marco de una industria responsable y sostenible.</p>
                </div>
            </div>
        </div>
    )
}
