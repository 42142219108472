import React from "react";

export default  function ContentBiografia(props) {
    const {item, headerType} = props;

    return (
        <>
            <div className={headerType.classContent}>
                <h5>{item.name}</h5>
                <p>{item.author}</p>
                <p>{item.isbn}</p>
                <br />
                <p>{item.description}</p>

                {/* Converir el string en html*/}
                <div dangerouslySetInnerHTML={{__html: item.content}}></div>
            </div>
        </>
    )
}
