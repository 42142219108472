import * as React from 'react';
import Vector from '../../../assets/Uploads/support/Vector.png'
import {useHistory} from "react-router-dom";
import {RouteAuth} from "../../../routes/RouteAuth";

export default function Block1() {
    const history = useHistory()

    return (
        <div className="block1">
            <div className="block1-container">
                <div className="block1-title">
                    <h1>Soporte</h1>
                </div>
                <div className="block1-text">
                    <p>SciFood Studio proporciona recursos, tutoriales y ejemplos reales para familiarizarse con el software e ilustrar su capacidad de calcular, optimizar, validar y verificar procesos industriales de alimentos.<br /><br />Te invitamos a conocer el área privada donde encontrarás recursos adicionales que están disponibles permanentemente.</p>
                    <div className="block1-client-access">
                        <img src={Vector} />
                        <p onClick={() => {history.push(RouteAuth.LOGIN.path)}} style={{cursor: 'pointer'}}>Acceso a clientes</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
