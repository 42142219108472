import React from "react";
import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {useTranslation} from "react-i18next";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import CategoryIcon from '@mui/icons-material/Category';
import AppsIcon from '@mui/icons-material/Apps';
import {RouteAdmin} from "../../../routes/RouteAdmin";
import {Link} from "react-router-dom";
import Divider from "@mui/material/Divider";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

export default function menuPrimary(props) {
    const {menu} = props
    const {t} = useTranslation()

    return (
        <div style={{marginTop: 20}}>
            <MenuLink type="users" menu={menu} href={RouteAdmin.USER_MANAGEMENT.path} name={t('user.users')} icon={<PeopleAltIcon />} />
            <MenuLink type="media" menu={menu} href={RouteAdmin.MEDIA_MANAGEMENT.path} name="Media" icon={<PermMediaIcon />}/>

            <MenuLink type="post" menu={menu} href={RouteAdmin.POST_MANAGEMENT.path} name="Contenidos" icon={<AppsIcon />} />
            <MenuLink type="distributor" menu={menu} href={RouteAdmin.DISTRIBUTOR_MANAGEMENT.path} name="Distribuidores" icon={<ConnectWithoutContactIcon />} />

            <Divider style={{marginBottom: 10, marginTop: 10}}/>
            <MenuLink type="type" menu={menu} href={RouteAdmin.TYPE_MANAGEMENT.path} name="Tipos" icon={<CategoryIcon />} />
            <MenuLink type="category" menu={menu} href={RouteAdmin.CATEGORY_MANAGEMENT.path} name="Categorias" icon={<CategoryIcon />}/>
            <MenuLink type="subcategory" menu={menu} href={RouteAdmin.SUBCATEGORY_MANAGEMENT.path} name="Subcategorias" icon={<CategoryIcon />}/>
        </div>
    )
}

function MenuLink(props) {
    const {href, name, menu, type, icon, endIcon} = props

    let isActive = false
    if (menu === type) {
        isActive = true
    }

    return (
        <>
            <div  style={{ marginRight: '25px' }}>
            <ListItem
                component={Link}
                to={href}
                selected={isActive}
                sx={{ borderRadius: '8px', marginLeft: '10px', color: '#000' }}
            >
                {endIcon && (
                    <ListItemIcon>
                        {endIcon}
                    </ListItemIcon>
                )}

                {!endIcon && (
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                )}
                <ListItemText primary={name} />

            </ListItem>
            </div>
        </>
    )
}
