import React, {useEffect, useState} from "react";
import {apiGetDistributor} from "../../../services/User/distributor";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Skeleton} from "@mui/material";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {RouteUser} from '../../../routes/RouteUser'

export default function Block1() {

    const {i18n} = useTranslation()
    const {id} = useParams()
    const [state, setState] = useState([])
    const [loading,setLoading] = useState(false)
    const [highest, setHighest] = useState(0)

    useEffect(() => {
        getDistributor()
    }, [])
    
    const getDistributor = () => {
        setLoading(true)
        apiGetDistributor({
            lang: i18n.resolvedLanguage
        }).then(resp => {
            setLoading(false)
            setState(resp.data?.data ?? [])
            getHeight()
        }).catch(err => {
            setLoading(false)
        })
    }

    const getHeight = () => {
        const newsBlokken =  Array.from(document.querySelectorAll('.distributor-container .distributor-content h5'))
        setHighest(Math.max(
            ...newsBlokken.map(item => item.getBoundingClientRect().height))
        )
    }

    return (
        <div className="block1">
            <div className="absolute-container">
                <div className="header-text">
                    <h1>Encuentra tu distribuidor</h1>
                    <p><span>¿Te interesan nuestras soluciones para mejorar la seguridad y calidad alimentaria?</span></p>
                    <br />
                    <p>Potencia la seguridad alimentaria y asegura la calidad de tus alimentos con los productos de SciFood Studio. Nuestra red de colaboradores de valor añadido proporciona un servicio de alto nivel, incluyendo además de la distribución de nuestros productos, servicios de formación, servicios de puesta en macha, servicios de laboratorio para caracterización de productos y análisis microbiológicos, así como soporte regular de continuidad.</p>
                </div>

                {loading && (<><div class="lds-roller" style={{justifyContent: 'center'}}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></>)}

                <div className="distributor-container">
                    {state.map((item, key) => {
                        return(
                            <div key={key} className="distributor-content" item={item}>
                                <h5 style={highest ? {height: `${highest}px`} : {}}>Distribuidor<br />{item.typeName !== null && (<>{item.typeName}<br/></>)}{item.name}</h5>
                                <div>
                                    <p>Dirección: {item.address}</p>
                                    <br />
                                    <p style={{wordBreak: 'break-all'}}>Web: {item.web}</p>
                                    <br />
                                    <p>Teléfono: {item.phone}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
