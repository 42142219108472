import React, {useEffect, useState} from "react";
import {apiGetPost} from "../../../services/User/post";
import {useTranslation} from "react-i18next";
import Typography from '@mui/material/Typography';
import ContentBiografia from "./Details/ContentBiografia";
import ContentTutoriales from "./Details/ContentTutoriales";
import ContentVideos from "./Details/ContentVideos";
import ContentDescargas from "./Details/ContentDescargas";
import ContentFaq from "./Details/ContentFaq";
import ContentLinks from "./Details/ContentLinks";
import {Skeleton} from "@mui/material";

export default function Block3(props) {

    const {idSubCategory, headerType} = props

    const {i18n} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState([])

    useEffect(() => {
        if (idSubCategory) {
            getPost()
        }
    }, [idSubCategory])

    const getPost = () => {
        setLoading(true)
        apiGetPost({
            subcategoryId: idSubCategory,
            lang: i18n.resolvedLanguage
        }).then(resp => {
            setLoading(false)
            setState(resp.data?.data ?? [])
        }).catch(err => {
            setLoading(false)
        })
    }

    return (
        <div className="block3">
            <div className={headerType.class}>
                {loading && (<LoadingDetails/>)}

                {state.map((item, key) => {
                    return (
                        <span key={key}>
                            {headerType.id == 8 && (
                                <>
                                    <ContentBiografia item={item} headerType={headerType}/>
                                </>
                            )}

                            {headerType.id == 10 && (
                                <>
                                    <ContentTutoriales item={item} headerType={headerType}/>
                                </>
                            )}

                            {headerType.id == 13 && (
                                <>
                                    <ContentVideos item={item} headerType={headerType}/>
                                </>
                            )}

                            {headerType.id == 11 && (
                                <>
                                    <ContentDescargas item={item} headerType={headerType}/>
                                </>
                            )}

                            {headerType.id == 9 && (
                                <>
                                    <ContentFaq item={item} headerType={headerType}/>
                                </>
                            )}

                            {headerType.id == 12 && (
                                <>
                                    <ContentLinks item={item} headerType={headerType}/>
                                </>
                            )}
                        </span>
                    )
                })}
            </div>
        </div>
    )
}


function LoadingDetails() {
    return (
        <>
            <Typography variant="h1" sx={{marginTop: -2}}><Skeleton/></Typography>

            <Skeleton animation="wave"/>
            <Skeleton animation="wave"/>
            <Skeleton animation="wave"/>

            <br/>

            <Typography variant="h1"><Skeleton/></Typography>

            <Skeleton animation="wave"/>
            <Skeleton animation="wave"/>
            <Skeleton animation="wave"/>

            <br/>

            <Typography variant="h1"><Skeleton/></Typography>

            <Skeleton animation="wave"/>
            <Skeleton animation="wave"/>
            <Skeleton animation="wave"/>

            <br/>

            <Typography variant="h1"><Skeleton/></Typography>

            <Skeleton animation="wave"/>
            <Skeleton animation="wave"/>
            <Skeleton animation="wave"/>

            <br/>
        </>
    )
}
