import * as React from 'react';
import Button from '@mui/material/Button';

export default function Block3(props) {

    const {scrollToRef} = props

    const executeScroll = () => scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 

    return (
        <div className="block3">
            <div className="block3-content">
                <div className="block3-text-bottom">
                    <h4>SciFood Studio es el programa que los estudiantes utilizarán en su futuro puesto de trabajo.</h4>
                    <Button className="solicitar-button" onClick={executeScroll}>Solicita información</Button>
                </div>
                <div className="">
                    
                </div>
            </div>
        </div>
    )
}
