import * as React from 'react';
import Image4 from '../../../assets/Uploads/home/shutterstock_745960519.webp'

export default function Block2() {

    return (
        <div className="block2">
            <div className="block2-relative-container">
                <div className="block2-content">
                    <div className="block2-text">
                        <h4>Anticípate a las auditorías y supera con éxito los controles de calidad más exigentes.</h4>
                        <p>El sector alimentario se enfrenta a exigencias cada vez más estrictas y las empresas que operan en él, necesitan herramientas para estar a la altura. SciFood Studio compila un paquete único de aplicaciones, dirigidas a expertos en el procesamiento de alimentos, que facilitan las decisiones que determinan la inocuidad y calidad de los alimentos.</p>
                        <p>Es un entorno de trabajo con herramientas software altamente profesionales y fáciles de usar, orientadas a gestionar la validación, verificación y vigilancia de los procesos de fabricación de alimentos, así como la optimización y la trazabilidad de los mismos, facilitando de este modo el éxito en las auditorías.</p>
                    </div>
                    <div className="block2-image-container">
                        <img src={Image4} className="block2-image" />
                    </div>
                </div>
            </div>
        </div>
    )
}