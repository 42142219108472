import React, {useEffect} from "react";
import useAuth from "../../../hooks/useAuth";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";

export default function AdminBar() {

    const {auth, login} = useAuth()

    const handleExitUser = async (e) => {
        await login({
            primaryUser: auth?.secondaryUser,
            secondaryUser: auth?.secondaryUser,
            backImpersonate: true
        })
    }

    if (auth?.secondaryUser?.role !== "ROLE_ADMIN") {return(<></>)}

    return (
        <div style={{width: "100vw", background: "black", position: "fixed", bottom: "0px", zIndex: "9999"}}>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" style={{backgroundColor: "#232323"}}>
                    <Toolbar>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >
                            Admin
                            {auth?.secondaryUser.name && (
                                ` | ${auth?.secondaryUser.name}`
                            )}
                        </Typography>

                        <button
                            type={"button"}
                            style={{color: "black", background: "white", border: "none", cursor: "pointer", padding: '10px 20px', marginRight: 20, borderRadius: 5}}
                            onClick={handleExitUser}
                        >
                            Dashboard
                        </button>
                    </Toolbar>
                </AppBar>
            </Box>
        </div>
    )
}


