import * as React from 'react';
import Button from '@mui/material/Button';
import Image10 from '../../../assets/Uploads/training/formacion_1.webp'

export default function Block1(props) {

    const {scrollToRef} = props

    const executeScroll = () => scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  

    return (
        <div className="block1">
            <div className="image1-container">
                <div className="block1-images-filter"></div>
                <img src={Image10} className="image1" />
            </div>
            <div className="absolute-container">
                <div className="header-text">
                    <h1>Aprende con<br/>casos y ejemplos reales</h1>
                </div>
            </div>
            <div className="block1-text-bottom">
                <h4>Sácale el máximo partido a SciFood con las sesiones de formación impartidas por expertos en procesado de alimentos.</h4>
                <Button className="solicitar-button" onClick={executeScroll}>Inscríbete</Button>
            </div>
        </div>
    )
}
