import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import {Box} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import InputSelect from "../../../../components/Inputs/InputSelect";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import {lang} from "../../../../utils/lang";

export default function Header(props) {

    const {state, setState, id, trans, msgError, msgSuccess} = props;

    const TransIn = trans ?? true

    const handleChangeTab = (event, newValue) => {
        state["type"] = newValue
        setState({...state})
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    return (
        <>
            <HeaderPage
                title="Configuración del contenido"
                urlBack={RouteAdmin.POST_MANAGEMENT.path}
            >
                { TransIn && (
                    <div style={{float: "right", margin: "25px 0px", width: 200}}>
                        <InputSelect
                            label={"Idioma"}
                            name="langSelect"
                            onChange={handleChange}
                            value={state?.langSelect}
                            options={lang}
                        />
                    </div>
                )}
            </HeaderPage>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={state.type} onChange={handleChangeTab} aria-label="nav tabs example">
                        <Tab component={Link} label="General" to={`${RouteAdmin.POST_EDIT_GENERAL.path}/${id}`} disabled={id ? false : true}/>
                        <Tab component={Link} label="Imágenes/vídeo" to={`${RouteAdmin.POST_EDIT_MEDIA.path}/${id}`} disabled={id ? false : true}/>
                        <Tab component={Link} label="Documentación" to={`${RouteAdmin.POST_EDIT_DOC.path}/${id}`} disabled={id ? false : true}/>
                        <Tab component={Link} label="SEO" to={`${RouteAdmin.POST_EDIT_SEO.path}/${id}`} disabled={id ? false : true}/>
                        <Tab component={Link} label="Contenido HTML" to={`${RouteAdmin.POST_EDIT_CONTENT.path}/${id}`} disabled={id ? false : true}/>
                    </Tabs>
                </Box>
            </Box>
        </>
    );
}
