import Block1 from "../../../components/User/LabNotes/block1"
import Block2 from "../../../components/User/LabNotes/block2"
import Block3 from "../../../components/User/LabNotes/block3"
import Block4 from "../../../components/User/LabNotes/block4"
import {useEffect} from "react";
import ReactGA from "react-ga4";
export default function LabNotes() {

    useEffect(() => {
        ReactGA.event({
            category: "Page",
            action: `LabNotes`,
        });
    },[])

    return (
        <div className="labnotes">
            <Block1 />
            <Block2 />
            <Block3 />
            <Block4 />
        </div>
    );
}
