import React from "react";
import {generalText} from "../../../../utils/generalText";
import {Button, IconButton} from "@mui/material";
import Image4 from '../../../../assets/Uploads/support/Vector_2.png'

export default function ContentTutoriales(props) {
    const {item, headerType} = props;

    const downloadFile = (photos, videos, documentation) => {
        photos.forEach(photo => {
            window.open(photo, '_blank');
        })

        videos.forEach(video => {
            window.open(video, '_blank');
        })

        documentation.forEach(document => {
            window.open(document, '_blank');
        })
    }

    return (
        <>
            <div className={headerType.classContent}>
                <div className="tutoriales-bloques">
                    <div className="tutoriales-title">
                        <h5>{item.name}</h5>
                        <p>{item.description}</p>
                    </div>
                    <div className="tutoriales-link">

                        <Button
                            onClick={() => downloadFile(item.photos, item.videos, item.documentation)}
                        >
                            <IconButton>
                                <img src={Image4} className="block2-image"/>
                            </IconButton>
                        </Button>
                    </div>
                </div>
                <p className="tutoriales-text">
                    <div dangerouslySetInnerHTML={{__html: generalText.tutoriales.text}}></div>
                </p>

                <br/>
            </div>
        </>
    )
}
