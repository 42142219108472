import * as React from 'react';
import Image6 from '../../../assets/Uploads/training/shutterstock_243756841_formacion_2.webp'

export default function Block2() {

    return (
        <div className="block2">
            <div className="block2-relative-container">
                <div className="block2-content">
                    <div className="block2-text">
                        <h4>Explora nuevos enfoques. Interactúa con expertos.</h4>
                        <p>Ya sea para familiarizarse con SciFood o para fortalecer sus habilidades, existen varios niveles de capacitación.</p>
                        <p>Los problemas comunes están cubiertos e ilustrados por estudios de casos y ejemplos con el software.</p>
                        <p>Sesiones orientadas a los que usan el software por primera vez, y también a los más experimentados, para que puedan mejorar.</p>
                    </div>
                    <div className="block2-image-container">
                        <img src={Image6} className="block2-image" />
                    </div>
                </div>
            </div>
        </div>
    )
}