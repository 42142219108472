import UserManagement from "../pages/Admin/User/Management"
import UserEdit from "../pages/Admin/User/Fields"
import UserCreate from "../pages/Admin/User/Fields"
import MediaManagement from "../pages/Admin/Media/Management"
import Testing from "../pages/Admin/Testing"
import CategoryManagement from "../pages/Admin/Category/Management"
import CategoryCreate from "../pages/Admin/Category/Fields"
import CategoryEdit from "../pages/Admin/Category/Fields"
import SubcategoryManagement from "../pages/Admin/Subcategory/Management"
import SubcategoryCreate from "../pages/Admin/Subcategory/Fields"
import SubcategoryEdit from "../pages/Admin/Subcategory/Fields"
import TypeManagement from "../pages/Admin/Type/Management"
import TypeCreate from "../pages/Admin/Type/Fields"
import TypeEdit from "../pages/Admin/Type/Fields"
import PostManagement from "../pages/Admin/Post/Management"
import PostCreate from "../pages/Admin/Post/Fields/General"
import PostEditGeneral from "../pages/Admin/Post/Fields/General"
import PostEditMedia from "../pages/Admin/Post/Fields/Media"
import PostEditDoc from "../pages/Admin/Post/Fields/Doc"
import PostEditContent from "../pages/Admin/Post/Fields/Content"
import PostEditSeo from "../pages/Admin/Post/Fields/Seo"
import DistributorManagement from "../pages/Admin/Distributor/Management"
import DistributorFields from "../pages/Admin/Distributor/Fields"

const baseTitle = "SciFood - "

export const RouteAdmin = {
    USER_MANAGEMENT: {
        path: "/admin/users/management",
        page: UserManagement,
        title: `${baseTitle}User Management`,
        menu: "users",
        roles: ["ROLE_ADMIN"]
    },
    USER_EDIT: {
        path: "/admin/users/edit",
        params: "/:id",
        page: UserEdit,
        title: `${baseTitle}User Edit`,
        menu: "users",
        roles: ["ROLE_ADMIN"]
    },
    USER_CREATE: {
        path: "/admin/users/create",
        page: UserCreate,
        title: `${baseTitle}User Create`,
        menu: "users",
        roles: ["ROLE_ADMIN"]
    },
    MEDIA_MANAGEMENT: {
        path: "/admin/media/management",
        page: MediaManagement,
        title: `${baseTitle}Media Management`,
        menu: "media",
        roles: ["ROLE_ADMIN"]
    },
    TESTING: {
        path: "/admin/testing",
        page: Testing,
        title: "",
        menu: "",
        roles: ["ROLE_ADMIN"]
    },
    CATEGORY_MANAGEMENT: {
        path: "/admin/category/management",
        page: CategoryManagement,
        title: `${baseTitle}Category Management`,
        menu: "category",
        roles: ["ROLE_ADMIN"]
    },
    CATEGORY_CREATE: {
        path: "/admin/category/create",
        page: CategoryCreate,
        title: `${baseTitle}Category Create`,
        menu: "category",
        roles: ["ROLE_ADMIN"]
    },
    CATEGORY_EDIT: {
        path: "/admin/category/edit",
        params: "/:id",
        page: CategoryEdit,
        title: `${baseTitle}Category Edit`,
        menu: "category",
        roles: ["ROLE_ADMIN"]
    },
    SUBCATEGORY_MANAGEMENT: {
        path: "/admin/subcategory/management",
        page: SubcategoryManagement,
        title: `${baseTitle}Category Management`,
        menu: "subcategory",
        roles: ["ROLE_ADMIN"]
    },
    SUBCATEGORY_CREATE: {
        path: "/admin/subcategory/create",
        page: SubcategoryCreate,
        title: `${baseTitle}Category Create`,
        menu: "subcategory",
        roles: ["ROLE_ADMIN"]
    },
    SUBCATEGORY_EDIT: {
        path: "/admin/subcategory/edit",
        params: "/:id",
        page: SubcategoryEdit,
        title: `${baseTitle}Category Edit`,
        menu: "subcategory",
        roles: ["ROLE_ADMIN"]
    },
    TYPE_MANAGEMENT: {
        path: "/admin/type/management",
        page: TypeManagement,
        title: `${baseTitle}Type Management`,
        menu: "type",
        roles: ["ROLE_ADMIN"]
    },
    TYPE_CREATE: {
        path: "/admin/type/create",
        page: TypeCreate,
        title: `${baseTitle}Type Create`,
        menu: "type",
        roles: ["ROLE_ADMIN"]
    },
    TYPE_EDIT: {
        path: "/admin/type/edit",
        params: "/:id",
        page: TypeEdit,
        title: `${baseTitle}Type Edit`,
        menu: "type",
        roles: ["ROLE_ADMIN"]
    },
    POST_MANAGEMENT: {
        path: "/admin/post/management",
        page: PostManagement,
        title: `${baseTitle}Post Management`,
        menu: "post",
        roles: ["ROLE_ADMIN"]
    },
    POST_CREATE: {
        path: "/admin/post/create",
        page: PostCreate,
        title: `${baseTitle}Post Create`,
        menu: "post",
        roles: ["ROLE_ADMIN"]
    },
    POST_EDIT_GENERAL: {
        path: "/admin/post/edit-general",
        params: "/:id",
        page: PostEditGeneral,
        title: `${baseTitle}Post Edit`,
        menu: "post",
        roles: ["ROLE_ADMIN"]
    },
    POST_EDIT_MEDIA: {
        path: "/admin/post/edit-media",
        params: "/:id",
        page: PostEditMedia,
        title: `${baseTitle}Post Edit`,
        menu: "post",
        roles: ["ROLE_ADMIN"]
    },
    POST_EDIT_DOC: {
        path: "/admin/post/edit-doc",
        params: "/:id",
        page: PostEditDoc,
        title: `${baseTitle}Post Edit`,
        menu: "post",
        roles: ["ROLE_ADMIN"]
    },
    POST_EDIT_SEO: {
        path: "/admin/post/edit-seo",
        params: "/:id",
        page: PostEditSeo,
        title: `${baseTitle}Post Edit`,
        menu: "post",
        roles: ["ROLE_ADMIN"]
    },
    POST_EDIT_CONTENT: {
        path: "/admin/post/edit-content",
        params: "/:id",
        page: PostEditContent,
        title: `${baseTitle}Post Edit`,
        menu: "post",
        roles: ["ROLE_ADMIN"]
    },
    DISTRIBUTOR_MANAGEMENT: {
        path: "/admin/distributor/management",
        page: DistributorManagement,
        title: `${baseTitle}Distributor Management`,
        menu: "distributor",
        roles: ["ROLE_ADMIN"]
    },
    DISTRIBUTOR_CREATE: {
        path: "/admin/distributor/create",
        page: DistributorFields,
        title: `${baseTitle}Distributor Create`,
        menu: "distributor",
        roles: ["ROLE_ADMIN"]
    },
    DISTRIBUTOR_EDIT: {
        path: "/admin/distributor/edit",
        params: "/:id",
        page: DistributorFields,
        title: `${baseTitle}Distributor Edit`,
        menu: "distributor",
        roles: ["ROLE_ADMIN"]
    },
}
