import * as React from 'react';
import Image10 from '../../../assets/Uploads/student/shutterstock_1077839366_estudiantes_1.webp'

export default function Block1() {

    return (
        <div className="block1">
            <div className="image1-container">
                <div className="block1-images-filter"></div>
                <img src={Image10} className="image1" />
            </div>
            <div className="absolute-container">
                <div className="header-text">
                    <h1>La ventaja competitiva del mañana</h1>
                </div>
            </div>
        </div>
    )
}
