import * as React from 'react';
import Image6 from '../../../assets/Uploads/student/shutterstock_746700040_estudiantes_2.webp'

export default function Block2() {

    return (
        <div className="block2">
            <div className="block2-relative-container">
                <div className="block2-content">
                    <div className="block2-text">
                        <h4>Forma a los estudiantes con la herramienta que emplea la industria de la alimentación hoy en día.</h4>
                        <p>El conocimiento práctico de una herramienta de validación y verificación de tratamientos térmicos de alimentos suma una enorme ventaja competitiva que los estudiantes podrán añadir a su currículum como técnicos en procesamiento de alimentos.</p>
                    </div>
                    <div className="block2-image-container">
                        <img src={Image6} className="block2-image" />
                    </div>
                </div>
            </div>
        </div>
    )
}