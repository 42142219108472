import * as React from 'react';
import Image1 from '../../../assets/Uploads/AppertFlow/image_4.png'
import HeaderLogo from '../../../assets/Uploads/AppertFlow/appertflow__imagotipo--negativo_1.png'
import Image3 from '../../../assets/Uploads/AppertFlow/Gráfica-2-AppertFlow-Web-4.webp'

export default function Block1() {

    return (
        <div className="block1">
            <div className="image1-container">
                <img src={Image1} className="image1" />
            </div>
            <div className="absolute-container">
                <div className="header-text">
                    <img src={HeaderLogo} className="header-logo" />
                    <h1>Validación, Vigilancia y Verificación.</h1>
                </div>
                <div className="absolute-blocks">
                    <img src={Image3} className="Image5" />
                </div>
            </div>
        </div>
    )
}
