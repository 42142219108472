import React from "react";
import {Button, Fade, ListItemIcon, Menu, MenuItem} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function MenuAccount() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const {logout} = useAuth();
    const {auth} = useAuth();
    const idUser = auth ? auth?.primaryUser?.idUser : null;
    const name = auth ? auth?.primaryUser?.name : null;
    const surnames = auth ? auth?.primaryUser?.surnames : null;
    const history = useHistory();
    const {t} = useTranslation()

    const myAccount = () => {
        history.push(`/admin/users/edit/${idUser}`)
    }
    return (
        <div className="menu-account">
            <Button
                id="fade-button"
                aria-controls="fade-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                style={{color: "black"}}
            >
                <ListItemIcon>
                    <AccountCircleIcon style={{color: "black"}}/>
                </ListItemIcon>
                {name ? name : ""} {surnames ? surnames : ""}
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={myAccount}>{t('my_account')}</MenuItem>
                <MenuItem onClick={logout}>{t('logout')}</MenuItem>
            </Menu>
        </div>
    )
}
